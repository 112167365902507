import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { AmountObj, Nil, NumberUtils } from '@pbt/pbt-ui-components'

import { Invoice } from '../../../types/entities/finance'

const useStyles = makeStyles((theme) => ({
  tableCell: {
    textAlign: 'right',
    fontSize: '1.4rem',
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.colors.grayGray3}`,
    borderStyle: 'none solid',
    fontFamily: 'Rubik, sans-serif',
  },
  alignLeft: {
    textAlign: 'left',
    width: '160px',
  },
  alignCenter: {
    textAlign: 'center',
  },
  estimatePaymentTable: {
    tableLayout: 'auto',
    textAlign: 'right',
    maxWidth: '360px',
    border: 'none',
  },
  estimatePaymentTableMobile: {
    minWidth: '100%',
    maxWidth: '100%',
  },
  tableHeaderCell: {
    fontWeight: '500',
    fontSize: '1.2rem',
    color: theme.colors.grayGray2,
    padding: theme.spacing(1),
  },
  tableHeaderTopBorder: {
    borderTopStyle: 'solid',
  },
  depositSection: {
    backgroundColor: theme.colors.grayGray4,
  },
  depositDueCell: {
    padding: theme.spacing(1, 1, 0.5),
    fontSize: '1.6rem',
    borderStyle: 'solid none none',
    '&:first-child': {
      borderStyle: 'solid none none solid',
      paddingRight: 0,
    },
    '&:last-child': {
      borderStyle: 'solid solid none none',
    },
  },
  depositDueLabelCell: {
    fontWeight: 500,
    color: theme.colors.grayGray1,
  },
  depositMessageCell: {
    fontSize: '1.2rem',
    padding: theme.spacing(1),
    border: `1px solid ${theme.colors.grayGray3}`,
    borderTop: 'none',
    minWidth: '360px',
  },
  estimatedTotalCell: {
    borderTop: `1px solid ${theme.colors.grayGray3}`,
    padding: theme.spacing(1, 1),
    borderBottomStyle: 'none',
  },
  labelCell: {
    width: '100%',
  },
  dataCell: {
    minWidth: 200,
    maxWidth: 200,
  },
  dataCellRanged: {
    minWidth: 100,
    maxWidth: 100,
  },
}))

interface CellConfig {
  amount?: number | AmountObj
  commonCellClassName: string
  hideHighValue?: boolean
  high?: number
  label: string
  labelCellClassName?: string
  low?: number
}

interface EstimatePaymentDetailsProps {
  readonly includeTopBorder?: boolean
  readonly invoice: Invoice | Nil
  readonly isMobile?: boolean
}

const EstimatePaymentDetails = ({
  invoice,
  includeTopBorder = true,
  isMobile = false,
}: EstimatePaymentDetailsProps) => {
  const { t } = useTranslation('Common')

  const classes = useStyles()

  const {
    subtotal = 0,
    totalDiscount = 0,
    totalTax = 0,
    dueToPay = 0,
    requiredDeposit = 0,
  } = invoice || {}

  const isDepositRequired = requiredDeposit !== 0
  const isSubTotalRanged = NumberUtils.isAmountObj(subtotal)
  const isTotalDiscountRanged = NumberUtils.isAmountObj(totalDiscount)
  const isTotalTaxRanged = NumberUtils.isAmountObj(totalTax)
  const isDueToPayRanged = NumberUtils.isAmountObj(dueToPay)
  const isAnyAmountRanged =
    isSubTotalRanged ||
    isTotalDiscountRanged ||
    isTotalTaxRanged ||
    isDueToPayRanged

  const cellConfigs: CellConfig[] = [
    {
      label: t('Common:SUBTOTAL'),
      commonCellClassName: '',
      ...(isSubTotalRanged ? { low: subtotal.low, high: subtotal.high } : {}),
      amount: subtotal,
    },
    {
      label: t('Common:DISCOUNT'),
      commonCellClassName: '',
      ...(isTotalDiscountRanged
        ? { low: totalDiscount.low, high: totalDiscount.high }
        : {}),
      amount: totalDiscount,
    },
    {
      label: t('Common:TAX'),
      commonCellClassName: '',
      ...(isTotalTaxRanged ? { low: totalTax.low, high: totalTax.high } : {}),
      amount: totalTax,
    },
    {
      label: t('Common:ESTIMATED_TOTAL'),
      commonCellClassName: classes.estimatedTotalCell,
      ...(isSubTotalRanged ? { low: subtotal.low, high: subtotal.high } : {}),
      amount: subtotal,
    },
    {
      label: t('Common:DEPOSIT_DUE_TODAY'),
      hideHighValue: true,
      commonCellClassName: classNames(
        classes.depositSection,
        classes.depositDueCell,
      ),
      labelCellClassName: classes.depositDueLabelCell,
      ...(isDueToPayRanged ? { low: dueToPay.low, high: dueToPay.high } : {}),
      amount: dueToPay,
    },
  ]

  function lowAndHighCells(value: CellConfig) {
    return (
      <>
        <td
          className={classNames(
            classes.tableCell,
            classes.dataCellRanged,
            value.commonCellClassName,
          )}
        >
          {NumberUtils.formatMoney(value.low ?? value.amount)}
        </td>
        <td
          className={classNames(
            classes.tableCell,
            classes.dataCellRanged,
            value.commonCellClassName,
          )}
        >
          {value.hideHighValue
            ? ''
            : NumberUtils.formatMoney(value.high ?? value.amount)}
        </td>
      </>
    )
  }

  const headerClasses = classNames(classes.tableCell, classes.tableHeaderCell, {
    [classes.tableHeaderTopBorder]: includeTopBorder,
  })

  return (
    <table
      className={classNames(classes.estimatePaymentTable, {
        [classes.estimatePaymentTableMobile]: isMobile,
      })}
    >
      <tr>
        <td className={headerClasses} />
        {isAnyAmountRanged ? (
          <>
            <th className={headerClasses}>{t('Common:LOW_TOTAL')}</th>
            <th className={headerClasses}>{t('Common:HIGH_TOTAL')}</th>
          </>
        ) : (
          <th className={headerClasses}> {t('Common:TOTAL')}</th>
        )}
      </tr>
      <tbody>
        {cellConfigs.map((it) => (
          <tr key={it.label}>
            <td
              className={classNames(
                classes.tableCell,
                classes.alignLeft,
                classes.labelCell,
                it.labelCellClassName,
                it.commonCellClassName,
              )}
            >
              {it.label}
            </td>
            {isAnyAmountRanged ? (
              lowAndHighCells(it)
            ) : (
              <td
                className={classNames(
                  classes.tableCell,
                  it.commonCellClassName,
                  {
                    [classes.alignCenter]: isAnyAmountRanged,
                    [classes.dataCell]: !isAnyAmountRanged && !isMobile,
                    [classes.dataCellRanged]: isAnyAmountRanged || isMobile,
                  },
                )}
                colSpan={2}
              >
                {NumberUtils.formatMoney(it.amount)}
              </td>
            )}
          </tr>
        ))}

        <tr>
          <td
            className={classNames(
              classes.depositSection,
              classes.depositMessageCell,
            )}
            colSpan={3}
          >
            {isDepositRequired &&
              (isAnyAmountRanged
                ? t('Common:DEPOSIT_RANGED_TOTAL_MESSAGE', {
                    percentage: requiredDeposit * 100,
                  })
                : t('Common:DEPOSIT_TOTAL_MESSAGE', {
                    percentage: requiredDeposit * 100,
                  }))}
            {!isDepositRequired && t('Common:DEPOSIT_NOT_REQUIRED_MESSAGE')}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default EstimatePaymentDetails
