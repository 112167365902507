import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PuiTextArea, useTimeout } from '@pbt/pbt-ui-components'
import { WarnAlert } from '@pbt/pbt-ui-components/src/icons'

import { getCurrentBusiness } from '../../store/duck/businesses'
import {
  clearLastError,
  getKioskDisabled,
  getLastError,
  setKioskDisabled,
} from '../../store/duck/errors'
import { useNavigateWithQueryString } from '../../utils'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    item: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(5),
      },
    },
    text: {
      whiteSpace: 'pre-wrap',
    },
    textArea: {
      maxWidth: `calc(100% - ${theme.spacing(2)})`,
      width: 500,
    },
    icon: {
      width: 32,
      height: 32,
      marginBottom: theme.spacing(2),
      color: theme.colors.alertWarning,
    },
    link: {
      cursor: 'pointer',
      fontSize: '2.6rem',
      textDecoration: 'underline',
      color: theme.colors.title,
    },
  }),
  { name: 'ErrorScreen' },
)

const ErrorScreen = ({ rootUrl = '/', onNavigate }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Error'])
  const lastError = useSelector(getLastError)
  const kioskDisabled = useSelector(getKioskDisabled)

  const businessId = useSelector(getCurrentBusiness)

  const hasErrorToShow = kioskDisabled || lastError?.data

  const navigate = () => {
    if (onNavigate) {
      onNavigate()
    }
    dispatch(clearLastError())
    dispatch(setKioskDisabled(false))
    navigateWithQueryString({ url: rootUrl })
  }

  useTimeout(() => {
    navigate()
  }, 50000)

  useEffect(() => {
    if (!hasErrorToShow) {
      navigate()
    }
  }, [hasErrorToShow])

  return (
    <KioskScreen>
      <Grid
        container
        item
        alignItems="center"
        className={classes.root}
        direction="column"
        justifyContent="center"
      >
        <Grid item>
          <WarnAlert className={classes.icon} />
        </Grid>
        <Grid item className={classes.item}>
          <Typography align="center" className={classes.text} variant="h5">
            {t('Common:SOMETHING_WENT_WRONG_DID_YOU_WANT_TO_TRY_AGAIN')}
          </Typography>
        </Grid>
        {hasErrorToShow && (
          <PuiTextArea
            disabled
            className={classes.textArea}
            value={
              kioskDisabled
                ? t('Error:KIOSK_DISABLED_MESSAGE', {
                    email: 'support@rhapsody.vet',
                  })
                : typeof lastError.data === 'string'
                ? lastError.data
                : `${lastError.data.description || ''}`
            }
          />
        )}
        {businessId && (
          <Grid item className={classes.item}>
            <Typography className={classes.link} onClick={navigate}>
              {t('Common:START_OVER')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </KioskScreen>
  )
}

export default ErrorScreen
