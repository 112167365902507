import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ConsentForm,
  DateDocumentElement,
  DateTimeDocumentElement,
  DocumentElementType,
  MultiSelectDocumentElement,
  SignatureDocumentElement,
  SignatureDocumentElementFieldValue,
  SingleCheckboxDocumentElement,
  SingleSelectDocumentElement,
  TextDocumentElement,
} from '@pbt/pbt-ui-components'

import {
  fetchDocumentsListV2,
  getDocumentsV2IsLoading,
  getDocumentsV2List,
  signDocumentV2,
} from '../../../store/duck/documentsV2'
import { getCurrentAppointment } from '../../../store/duck/schedules'
import { PaginatedIdentifiableDocumentInstance } from '../../../types/entities/consentForm'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../utils'
import {
  paginateDocument,
  prepareDocumentForSave,
} from '../../../utils/consentFormUtils'
import useCallbackWhenLoaded from '../../../utils/useCallbackWhenLoaded'
import KioskScreen from '../KioskScreen'

export function ConsentFormScreen() {
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const appointment = useSelector(getCurrentAppointment)
  const documentsList = useSelector(getDocumentsV2List)
  const [paginatedDocumentsList, setPaginatedDocumentsList] = useState<
    PaginatedIdentifiableDocumentInstance[]
  >([])
  const isLoading = useSelector(getDocumentsV2IsLoading)

  const appointmentId = appointment && 'id' in appointment ? appointment.id : ''

  const onDocumentsLoaded = () => {
    const paginatedDocuments = documentsList
      .filter((document) => document.documentElements.length > 0)
      .map((document) => paginateDocument(document))

    if (paginatedDocuments.length === 0) {
      navigateWithQueryString({ url: '/consent-forms-complete' })
    } else {
      setPaginatedDocumentsList(paginatedDocuments)
    }
  }

  const callbackWhenLoaded = useCallbackWhenLoaded(
    onDocumentsLoaded,
    getDocumentsV2IsLoading,
  )

  useEffect(() => {
    callbackWhenLoaded()
    dispatch(fetchDocumentsListV2({ appointmentId }))
  }, [appointmentId])

  const handleFormSubmit = (formIndex: number, isFinalPage: boolean) => {
    // dispatch save, then nav on complete
    const signedDocument = prepareDocumentForSave(
      paginatedDocumentsList[formIndex],
    )
    dispatch(signDocumentV2({ document: signedDocument }))

    if (isFinalPage) {
      navigateWithQueryString({ url: '/consent-forms-complete' })
    }
  }

  const handleDocumentElementValueChange =
    (formIndex: number, pageIndex: number) =>
    (
      elementIndex: number,
      value:
        | string
        | string[]
        | boolean
        | SignatureDocumentElementFieldValue
        | null,
    ) => {
      const updatedFormsList = [...paginatedDocumentsList]
      const updatedForm = { ...updatedFormsList[formIndex] }
      const updatedPage = [...updatedForm.pages[pageIndex]]
      const updatedElement = { ...updatedPage[elementIndex] }

      switch (updatedElement.type) {
        case DocumentElementType.MULTI_SELECT:
          ;(updatedElement as MultiSelectDocumentElement).selectedOptionIds =
            value as string[]
          break
        case DocumentElementType.SINGLE_SELECT:
          ;(updatedElement as SingleSelectDocumentElement).selectedOptionId =
            value as string | null
          break
        case DocumentElementType.SINGLE_CHECKBOX:
          ;(updatedElement as SingleCheckboxDocumentElement).selected =
            value as boolean
          break
        case DocumentElementType.TEXT_FIELD:
          ;(updatedElement as TextDocumentElement).value = value as string
          break
        case DocumentElementType.DATE:
          ;(updatedElement as DateDocumentElement).date = value as string
          break
        case DocumentElementType.DATE_TIME:
          ;(updatedElement as DateTimeDocumentElement).dateTime =
            value as string
          break
        case DocumentElementType.SIGNATURE: {
          const { signature, signer } =
            value as SignatureDocumentElementFieldValue
          ;(updatedElement as SignatureDocumentElement).signature =
            signature as string
          ;(updatedElement as SignatureDocumentElement).signer =
            signer as string
          break
        }
        default:
          break
      }

      updatedPage[elementIndex] = updatedElement
      updatedForm.pages[pageIndex] = updatedPage
      updatedFormsList[formIndex] = updatedForm
      setPaginatedDocumentsList(updatedFormsList)
    }

  return (
    <KioskScreen proceedButtonLoading={isLoading}>
      {paginatedDocumentsList.length > 0 ? (
        <>
          <ConsentForm
            containerSxProps={{ height: 0 }}
            forms={paginatedDocumentsList}
            readOnly={false}
            onFieldChange={handleDocumentElementValueChange}
            onSubmit={handleFormSubmit}
          />
        </>
      ) : null}
    </KioskScreen>
  )
}
