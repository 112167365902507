import React, { forwardRef, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { getInvoice, getPaymentIsPaid } from '../../../store/duck/finance'
import KioskScreen from '../KioskScreen'
import EstimatePaymentDetails from './EstimatePaymentDetails'
// @ts-ignore
import InvoiceTitle from './invoice/InvoiceTitle'
// @ts-ignore
import InvoicePaymentDetailsScreen from './InvoicePaymentDetails'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 1, 1),
      },
      padding: theme.spacing(3, 6, 3, 3),
    },
    rightContainer: {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
      },
    },
    content: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 1),
      },
    },
  }),
  { name: 'PaymentDetailsScreen' },
)

interface PaymentDetailsScreenProps extends PropsWithChildren<any> {
  readonly footer?: React.ReactNode
  readonly showRemainingBalance?: boolean
}

const PaymentDetailsScreen = forwardRef<
  HTMLInputElement,
  PaymentDetailsScreenProps
>(function PaymentDetailsScreen(
  { showRemainingBalance = false, children, footer, ...rest },
  ref,
) {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const isPaid = useSelector(getPaymentIsPaid)

  const invoice = useSelector(getInvoice)
  const isEstimate = invoice && invoice.type === 'Estimate'

  return (
    <KioskScreen
      alignItems="flex-start"
      justifyContent="flex-start"
      proceedButtonLabel={
        isEstimate
          ? t('Common:NEXT_ADD_PAYMENT_METHOD')
          : t('Common:CONTINUE_TO_PAYMENT')
      }
      ref={ref}
      {...rest}
    >
      <InvoiceTitle invoice={invoice} />
      <Grid container item alignItems="flex-start" className={classes.root}>
        {invoice?.type === 'Estimate' ? (
          <EstimatePaymentDetails invoice={invoice} />
        ) : (
          <InvoicePaymentDetailsScreen
            invoice={invoice}
            isPaid={isPaid}
            showRemainingBalance={showRemainingBalance}
          />
        )}
        <Grid
          container
          item
          xs
          className={classNames(classes.rightContainer, classes.content)}
          direction="column"
        >
          {children}
        </Grid>
      </Grid>
      {footer}
    </KioskScreen>
  )
})

export default PaymentDetailsScreen
