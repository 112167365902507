import * as R from 'ramda'
import { DocumentElement, DocumentElementType } from '@pbt/pbt-ui-components'

import {
  IdentifiableDocumentInstance,
  MaybeSavedDocumentInstance,
  PaginatedIdentifiableDocumentInstance,
} from '../types/entities/consentForm'

// Convert document to paginated form version for display in Kiosk
export function paginateDocument(
  document: IdentifiableDocumentInstance,
): PaginatedIdentifiableDocumentInstance {
  const sortedElements = R.sort(
    R.ascend(R.prop('sequenceNumber')),
    document.documentElements,
  )

  const pages: Array<Array<DocumentElement>> = [[]]
  let currentPage = 0

  for (let i = 0; i < sortedElements.length; i++) {
    if (sortedElements[i].type === DocumentElementType.PAGE_BREAK) {
      currentPage++
      pages.push([])
    }
    pages[currentPage].push(sortedElements[i])
  }

  return {
    ...document,
    pages,
  }
}

// Populate original document elements with values from paginated version for save
export function prepareDocumentForSave(
  paginatedDocument: PaginatedIdentifiableDocumentInstance,
): MaybeSavedDocumentInstance {
  const document: PaginatedIdentifiableDocumentInstance & {
    documentElements: DocumentElement[]
  } = { ...paginatedDocument, documentElements: [] }

  for (let i = 0; i < document.pages.length; i++) {
    const page = document.pages[i]
    for (let j = 0; j < page.length; j++) {
      document.documentElements.push(page[j])
    }
  }

  const fieldsToOmit = ['pages', 'generatedId']
  if (paginatedDocument.generatedId === true) {
    fieldsToOmit.push('id')
  }
  // @ts-ignore
  return R.omit(fieldsToOmit, document)
}
