import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import {
  getCurrentBusinessId,
  getCurrentBusinessIsOmniChannel,
} from '../../../../store/duck/businesses'
import {
  fetchConstants,
  getConstantsIsInitialized,
} from '../../../../store/duck/constants'
import {
  getInvoice,
  updateHasAgreedToPay,
  // @ts-ignore
} from '../../../../store/duck/finance'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
import KioskScreen from '../../KioskScreen'
// @ts-ignore
import EstimatePaymentDetails from '../EstimatePaymentDetails'
// @ts-ignore
import PayButtonSection from '../PayButtonSection'
import EstimateSummary from './EstimateSummary'
import EstimateTable from './EstimateTable'
import EstimateTitle from './EstimateTitle'

const useStyles = makeStyles(
  (theme) => ({
    tableContainer: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(var(--app-height) - 278px)',
      },
      overflowY: 'auto',
      maxHeight: 'calc(var(--app-height) - 317px)',
      ...theme.constants.scrollbar,
    },
  }),
  { name: 'EstimateScreen' },
)

const EstimateScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const dispatch = useDispatch()
  const constantsInitialized = useSelector(getConstantsIsInitialized)
  const businessId = useSelector(getCurrentBusinessId)
  const invoice = useSelector(getInvoice)
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isDepositRequired = invoice?.requiredDeposit !== 0
  const hideNextButton = isCurrentBusinessOmniChannel && !isDepositRequired

  const { t } = useTranslation('Common')

  const invoices = invoice ? invoice?.invoices || [invoice] : []

  // Constants may have been initialized by payment root screen
  useEffect(() => {
    if (businessId && !constantsInitialized) {
      dispatch(fetchConstants(businessId))
    }
  }, [businessId, constantsInitialized])

  const proceed = () => {
    dispatch(updateHasAgreedToPay(true))
    navigateWithQueryString({ url: '/payment/billing-address' })
  }

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <KioskScreen alignItems="flex-start" justifyContent="flex-start">
      <Grid
        container
        item
        className={classes.tableContainer}
        direction="column"
        wrap="nowrap"
      >
        {invoices.map((internalInvoice) => (
          <React.Fragment key={internalInvoice.invoiceNo}>
            <EstimateTitle invoice={internalInvoice} />
            <EstimateTable invoice={internalInvoice} isMobile={isMobile} />
            {invoices.length > 1 && (
              <EstimateSummary invoice={internalInvoice} />
            )}
          </React.Fragment>
        ))}
        <Grid alignSelf="flex-end">
          <EstimatePaymentDetails
            includeTopBorder={false}
            invoice={invoice}
            isMobile={isMobile}
          />
        </Grid>
      </Grid>
      {!hideNextButton && (
        <PayButtonSection
          alignment="right"
          buttonTextOverride={t('Common:NEXT_ACTION')}
          onProceed={proceed}
        />
      )}
    </KioskScreen>
  )
}

export default EstimateScreen
