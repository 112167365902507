import React, { RefObject } from 'react'
import Dotdotdot from 'react-dotdotdot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { parse } from 'query-string'
import * as R from 'ramda'
import {
  DateFormat,
  ImageScalingUtils,
  moment,
  Patient,
  PatientAvatar,
  PuiTooltip,
  Text,
  Utils,
  WellnessPlan,
  WellnessPlanVersion,
} from '@pbt/pbt-ui-components'
import { Info as InfoIcon } from '@pbt/pbt-ui-components/src/icons'

// @ts-ignore
import { WellnessPlanLevels } from '../../../../constants/wellnessPlansConstants'
import { getCurrentBusiness } from '../../../../store/duck/businesses'
// @ts-ignore
import { getCurrentClient } from '../../../../store/duck/clients'
import {
  getSpecies,
  getWellnessPlanType,
} from '../../../../store/duck/constants'
import { SelectionType } from '../../../../store/duck/wellnessPlans'
import { Membership } from '../../../../types/entities/clients'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
// @ts-ignore
import { sortMembershipsByPriorityPatient } from '../../../../utils/membershipUtils'
import KioskLinkButton from '../../../buttons/KioskLinkButton'
// @ts-ignore
import MembershipTotalsLabel from '../MembershipTotalsLabel'
// @ts-ignore
import {
  getEnabledPlans,
  getPlanByLevel,
  useGroupedWellnessPlans,
  // @ts-ignore
} from '../wellnessPlanUtils'
// @ts-ignore
import WellnessPlanControlLabel from './WellnessPlanControlLabel'

const AVATAR_SIZE = 40

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    header: {
      height: 50,
      flexShrink: 0,
      zIndex: 1,
      top: 0,
      backgroundColor: theme.colors.tableBackground,
      position: 'sticky',
      borderBottom: theme.constants.tableBorder,
    },
    patientAvatar: {
      marginRight: theme.spacing(1),
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
    },
    patientAvatarEnrolled: {
      opacity: 0.5,
    },
    defaultIcon: {
      fontSize: '3rem',
    },
    patientName: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.8rem',
    },
    patientNameEnrolled: {
      color: theme.colors.tabLabel,
    },
    patientInfo: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    patientInfoContent: {
      height: 'fit-content',
    },
    row: {
      flexShrink: 0,
      '&:nth-of-type(even)': {
        backgroundColor: theme.colors.tableOddRowBackground,
      },
      paddingTop: theme.spacing(2),
    },
    label: {
      flex: 1,
      fontSize: '1.6rem',
    },
    button: {
      fontSize: '1.4rem',
    },
    body: {
      position: 'relative',
      overflowY: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxHeight: ({
          isPortalEmbed,
          showBaseLevelPlanPrice,
        }: UseStylesProps) =>
          `calc(var(--app-height) - ${showBaseLevelPlanPrice ? 21 : 0}px - ${
            isPortalEmbed ? 282 : 455
          }px)`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
        maxHeight: ({ showBaseLevelPlanPrice }: UseStylesProps) =>
          `calc(var(--app-height) - ${
            showBaseLevelPlanPrice ? 21 : 0
          }px - 382px)`,
      },
      ...theme.constants.scrollbar,
    },
    radioGroup: {
      width: '100%',
    },
    formControlLabel: {
      marginTop: theme.spacing(0.75),
      flex: 1,
    },
    column: {
      flexShrink: 0,
      paddingRight: theme.spacing(1.5),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(5),
      },
    },
    planNameText: {
      marginTop: theme.spacing(1),
    },
    plansContainer: {
      flex: 1,
    },
    labelControl: {
      display: 'flex',
      alignSelf: 'flex-start',
    },
    icon: {
      color: theme.colors.link,
      cursor: 'pointer',
      marginLeft: theme.spacing(0.25),
    },
  }),
  { name: 'MembershipSignUpTable' },
)

interface UseStylesProps {
  buttonsColor?: string
  isPortalEmbed: boolean
  showBaseLevelPlanPrice: boolean
}

interface MembershipSignUpTableProps {
  readonly checkedExtrasMap: Record<string, SelectionType[]>
  readonly checkedPackagesMap: Record<string, SelectionType[]>
  readonly checkedTiersMap: Record<string, SelectionType>
  readonly onPlanPriceTypeChange: (
    plan: WellnessPlan,
    patient: Patient,
    value: string,
  ) => void
  readonly scrollRef: RefObject<HTMLDivElement>
  readonly togglePlanChecked: (plan: WellnessPlan, patient: Patient) => void
  readonly totals: Record<string, number>
  readonly wellnessPlanVersion: WellnessPlanVersion
}

const MembershipSignUpTable = ({
  scrollRef,
  totals,
  checkedTiersMap,
  checkedPackagesMap,
  checkedExtrasMap,
  togglePlanChecked,
  onPlanPriceTypeChange,
  wellnessPlanVersion,
}: MembershipSignUpTableProps) => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const Species = useSelector(getSpecies)
  const client = useSelector(getCurrentClient)
  const currentBusiness = useSelector(getCurrentBusiness)
  const WellnessPlanType = useSelector(getWellnessPlanType)

  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)
  const ExtraTypeId = Utils.findConstantIdByName('Extra', WellnessPlanType)

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const { search } = useLocation()
  const { portalEmbed, priorityPatientId } = parse(search)
  const isPortalEmbed = Boolean(portalEmbed)

  const { customColorsEnabled, buttonsColor } = currentBusiness || {}

  const allPlans = wellnessPlanVersion?.plans || []
  const baseLevelPlan = getPlanByLevel(WellnessPlanLevels.BASE, allPlans)
  const showBaseLevelPlanPrice = baseLevelPlan?.price > 0
  const isBaseHidden = wellnessPlanVersion?.basePlanHidden

  const classes = useStyles({
    showBaseLevelPlanPrice,
    isPortalEmbed,
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  const plans: WellnessPlan[] = getEnabledPlans(wellnessPlanVersion)
  const memberships: Membership[] = sortMembershipsByPriorityPatient(
    priorityPatientId,
  )(client?.memberships || [])

  const groupWellnessPlans = useGroupedWellnessPlans()
  const {
    packages = [],
    extras = [],
    tiers = [],
  }: {
    extras?: WellnessPlan[]
    packages?: WellnessPlan[]
    tiers?: WellnessPlan[]
  } = groupWellnessPlans(plans)

  const hasTiers = tiers.length > 0
  const hasPackages = packages.length > 0
  const hasExtras = extras.length > 0

  const goToFullDetails = () => {
    navigateWithQueryString({ url: '/membership/plan-details' })
  }

  return (
    <Grid
      container
      item
      className={classes.root}
      direction="column"
      wrap="nowrap"
    >
      {!isMobile && (
        <Grid
          container
          item
          alignItems="center"
          className={classes.header}
          pr={4}
        >
          <Grid item className={classes.patientInfo} xs={3}>
            <KioskLinkButton
              className={classes.button}
              color="secondary"
              onClick={goToFullDetails}
            >
              {t('Common:VIEW_FULL_DETAILS')}
            </KioskLinkButton>
          </Grid>
          <MembershipTotalsLabel totals={totals} />
        </Grid>
      )}
      <Grid
        container
        item
        className={classes.body}
        direction="column"
        ref={scrollRef}
        wrap="nowrap"
      >
        {memberships.map((membership) => {
          const checkedTierId =
            checkedTiersMap[membership.patient.id]?.planId || ''
          const hasCheckedTier = Boolean(checkedTierId)
          const checkedPackageIds = R.pluck(
            'planId',
            checkedPackagesMap[membership.patient.id] || [],
          )
          const checkedExtraIds = R.pluck(
            'planId',
            checkedExtrasMap[membership.patient.id] || [],
          )

          const checkedTierPriceTypeId =
            checkedTiersMap[membership.patient.id]?.priceTypeId || ''

          const membershipTier = (membership.plans || []).find(
            (plan) =>
              plan.planTypeId !== PackageTypeId &&
              plan.planTypeId !== ExtraTypeId,
          )
          const canSelectTier = !membershipTier
          const disableNoPlanSelection =
            (checkedPackageIds.length > 0 && !isBaseHidden) ||
            checkedExtraIds.length > 0

          const tierIdValue = checkedTierId || membershipTier?.planId || ''
          const tierPriceIdValue =
            checkedTierPriceTypeId || membershipTier?.priceTypeId || ''

          const checkedTier = tiers.find((plan) => plan.id === tierIdValue)

          const showExtraExpirationWarning = checkedTier?.autoRenewal === false
          const tierExpirationDate =
            checkedTier?.id === membershipTier?.planId
              ? moment(membershipTier?.cancelAtDate).format(
                  DateFormat.FULL_DATE_YEAR_ABBREV,
                )
              : moment().add(1, 'year').format(DateFormat.FULL_DATE_YEAR_ABBREV)

          const isAnotherMembershipVersion =
            membershipTier &&
            membershipTier.versionId !== wellnessPlanVersion.id

          const avatarSrc = ImageScalingUtils.getScaledImageOrOriginal(
            membership.patient.photo,
            membership.patient.photoThumbnail,
            AVATAR_SIZE,
          )

          return (
            <Grid
              container
              item
              className={classes.row}
              direction={isMobile ? 'column' : 'row'}
              id={membership.patient.id}
              key={membership.patient.id}
            >
              <Grid
                container
                item
                alignItems="center"
                className={classNames(
                  classes.patientInfo,
                  classes.patientInfoContent,
                )}
                sm={3}
                wrap="nowrap"
                xs={12}
              >
                <PatientAvatar
                  animal={Utils.getConstantName(
                    membership.patient.speciesId,
                    Species,
                  )}
                  classes={{
                    root: classNames(classes.patientAvatar, {
                      [classes.patientAvatarEnrolled]: membership.member,
                    }),
                    defaultIcon: classes.defaultIcon,
                  }}
                  src={avatarSrc}
                />
                <Dotdotdot clamp={2}>
                  <Text
                    className={classNames(classes.patientName, {
                      [classes.patientNameEnrolled]: membership.member,
                    })}
                    variant="body2"
                  >
                    {membership.patient.name}
                  </Text>
                </Dotdotdot>
              </Grid>
              <Grid
                container
                item
                xs
                className={classes.plansContainer}
                direction={isMobile ? 'column' : 'row'}
                wrap={isMobile ? 'nowrap' : 'wrap'}
              >
                {hasTiers && (
                  <Grid
                    container
                    item
                    className={classes.column}
                    direction="column"
                    sm={6}
                    xs={12}
                  >
                    <Grid item>
                      <Text strong variant="subheading3">
                        {canSelectTier
                          ? t('Membership:WELLNESS_PLANS_CHOOSE_ONE')
                          : t('Membership:WELLNESS_PLANS_CURRENTLY_ENROLLED')}
                      </Text>
                    </Grid>
                    <Grid
                      container
                      item
                      alignItems="flex-start"
                      direction="column"
                    >
                      {canSelectTier ? (
                        <RadioGroup
                          className={classes.radioGroup}
                          name="tiers"
                          value={tierIdValue}
                          onChange={(_, value) =>
                            togglePlanChecked(
                              Utils.findById(value, tiers),
                              membership.patient,
                            )
                          }
                        >
                          {tiers.map((tier: WellnessPlan) => (
                            <WellnessPlanControlLabel
                              className={classes.formControlLabel}
                              control={
                                <Radio className={classes.labelControl} />
                              }
                              key={tier.id}
                              plan={tier}
                              priceTypeId={tierPriceIdValue}
                              selected={tier.id === tierIdValue}
                              wellnessPlanVersion={wellnessPlanVersion}
                              onPlanPriceTypeChange={(value: string) => {
                                onPlanPriceTypeChange(
                                  tier,
                                  membership.patient,
                                  value,
                                )
                              }}
                            />
                          ))}
                          <FormControlLabel
                            className={classes.formControlLabel}
                            classes={{ label: classes.label }}
                            control={
                              <Radio
                                className={classes.labelControl}
                                disabled={disableNoPlanSelection}
                              />
                            }
                            label={t('Common:NO_PLAN')}
                            value=""
                          />
                        </RadioGroup>
                      ) : (
                        <Text className={classes.planNameText} variant="body2">
                          {membershipTier.planName}
                        </Text>
                      )}
                    </Grid>
                  </Grid>
                )}
                {hasPackages && (
                  <Grid
                    container
                    item
                    className={classes.column}
                    direction="column"
                    sm={6}
                    xs={12}
                  >
                    <Grid container item alignItems="center" wrap="nowrap">
                      <Text strong variant="subheading3">
                        {t('Membership:SUBSCRIBE_AND_SAVE_CHOOSE_ANY_NUMBER')}
                      </Text>
                      {isAnotherMembershipVersion && (
                        <PuiTooltip
                          tooltipPlacement="top-end"
                          tooltipText={t(
                            'Membership:MUST_BE_ENROLLED_IN_ACTIVE_VERSION_TO_SELECT_SNS_OR_EXTRAS',
                          )}
                        >
                          <InfoIcon className={classes.icon} />
                        </PuiTooltip>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      alignItems="flex-start"
                      direction="column"
                    >
                      {packages.map((packageItem: WellnessPlan) => {
                        const packagePriceValue = (
                          checkedPackagesMap[membership.patient.id] || []
                        ).find(
                          (item) => item.planId === packageItem.id,
                        )?.priceTypeId
                        const isSubscribed = (membership.plans || []).some(
                          ({ planId }) => planId === packageItem.id,
                        )
                        const isSelected = (checkedPackageIds || []).includes(
                          packageItem.id,
                        )
                        const packageSelected = isSubscribed || isSelected

                        return (
                          <WellnessPlanControlLabel
                            className={classes.formControlLabel}
                            control={
                              <Checkbox
                                checked={packageSelected}
                                className={classes.labelControl}
                                disabled={
                                  isSubscribed || isAnotherMembershipVersion
                                }
                                onChange={() =>
                                  togglePlanChecked(
                                    packageItem,
                                    membership.patient,
                                  )
                                }
                              />
                            }
                            key={packageItem.id}
                            plan={packageItem}
                            priceTypeId={packagePriceValue}
                            selected={packageSelected}
                            wellnessPlanVersion={wellnessPlanVersion}
                            onPlanPriceTypeChange={(value: string) => {
                              onPlanPriceTypeChange(
                                packageItem,
                                membership.patient,
                                value,
                              )
                            }}
                          />
                        )
                      })}
                    </Grid>
                  </Grid>
                )}
                {hasExtras && (
                  <Grid
                    container
                    item
                    className={classes.column}
                    direction="column"
                    sm={6}
                    xs={12}
                  >
                    <Grid container item alignItems="center" wrap="nowrap">
                      <Text strong variant="subheading3">
                        {t('Membership:WELLNESS_EXTRAS_CHOOSE_ANY_NUMBER')}
                      </Text>
                      {isAnotherMembershipVersion && (
                        <PuiTooltip
                          tooltipPlacement="top-end"
                          tooltipText={t(
                            'Membership:MUST_BE_ENROLLED_IN_ACTIVE_VERSION_TO_SELECT_SNS_OR_EXTRAS',
                          )}
                        >
                          <InfoIcon className={classes.icon} />
                        </PuiTooltip>
                      )}
                    </Grid>
                    {showExtraExpirationWarning && (
                      <Grid container item>
                        <Text variant="body2">
                          {t(
                            'Membership:ANY_EXTRAS_WILL_EXPIRE_DATE_WITH_PLAN',
                            {
                              expirationDate: tierExpirationDate,
                              planName: checkedTier.name,
                            },
                          )}
                        </Text>
                      </Grid>
                    )}
                    <Grid
                      container
                      item
                      alignItems="flex-start"
                      direction="column"
                    >
                      {extras.map((extra: WellnessPlan) => {
                        const extraPriceValue = (
                          checkedExtrasMap[membership.patient.id] || []
                        ).find((item) => item.planId === extra.id)?.priceTypeId
                        const isSubscribed = (membership.plans || []).some(
                          ({ planId }) => planId === extra.id,
                        )
                        const isSelected = (checkedExtraIds || []).includes(
                          extra.id,
                        )
                        const extraSelected = isSubscribed || isSelected
                        const isCheckExtraForbidden =
                          canSelectTier && !hasCheckedTier

                        return (
                          <WellnessPlanControlLabel
                            className={classes.formControlLabel}
                            control={
                              <Checkbox
                                checked={extraSelected}
                                disabled={
                                  isSubscribed ||
                                  isCheckExtraForbidden ||
                                  isAnotherMembershipVersion
                                }
                                onChange={() =>
                                  togglePlanChecked(extra, membership.patient)
                                }
                              />
                            }
                            key={extra.id}
                            plan={extra}
                            priceTypeId={extraPriceValue}
                            selected={extraSelected}
                            wellnessPlanVersion={wellnessPlanVersion}
                            onPlanPriceTypeChange={(value: string) => {
                              onPlanPriceTypeChange(
                                extra,
                                membership.patient,
                                value,
                              )
                            }}
                          />
                        )
                      })}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default MembershipSignUpTable
