import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CountryCode,
  LanguageUtils,
  PuiTextField,
  useFields,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'
// @ts-ignore
import {
  getCurrentClient,
  setIsCreatingClient,
  updateCurrentClient,
  // @ts-ignore
} from '../../store/duck/clients'
import { getReferralSources } from '../../store/duck/constants'
// @ts-ignore
import { useNavigateWithQueryString } from '../../utils'
import KioskSelectableButton from '../buttons/KioskSelectableButton'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(4, 5, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2, 0),
      },
    },
    text: {
      fontSize: '1.6rem',
    },
  }),
  { name: 'NewClientScreen' },
)

const NewClientScreen = () => {
  const classes = useStyles()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()

  const ReferralSources = useSelector(getReferralSources)
  const client = useSelector(getCurrentClient)
  const business = useSelector(getCurrentBusiness)

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const spacing = isMobile ? 0 : 4
  const chipSpacing = isMobile ? 2 : 3
  const isSpain = business?.countryCatalogCode === CountryCode.ES

  const {
    fields: { firstName, lastName, referralSourceId },
    validate,
  } = useFields(
    [
      {
        name: 'firstName',
        label: t('Common:FIRST_NAME'),
        validators: ['required'],
        initialValue: client?.firstName,
      },
      {
        name: 'lastName',
        label: t('Common:LAST_NAME'),
        validators: ['required'],
        initialValue: client?.lastName,
      },
      { name: 'referralSourceId', initialValue: client?.referralSourceId },
    ],
    false,
  )

  const onProceed = () => {
    if (validate()) {
      const newClient = {
        firstName: firstName.value,
        lastName: lastName.value,
        referralSourceId: referralSourceId.value,
      }

      dispatch(setIsCreatingClient(true))
      dispatch(updateCurrentClient(newClient))
      if (isSpain) {
        navigateWithQueryString({ url: '/gdpr-terms-es' })
      } else {
        navigateWithQueryString({ url: '/client-details' })
      }
    }
  }

  return (
    <KioskScreen
      justifyContent="flex-start"
      proceedButtonDisabled={!firstName.value || !lastName.value}
      proceedButtonLabel={t('Common:NEXT_ACTION')}
      title={t('Common:WELCOME_LET_US_KNOW_LITTLE_BIT_ABOUT_YOU')}
      onProceed={onProceed}
    >
      <Grid container item className={classes.root} spacing={spacing}>
        <Grid item sm={6} xs={12}>
          <PuiTextField
            fullWidth
            field={firstName}
            inputProps={{ maxLength: 100 }}
            label={`${firstName.label}*`}
            margin="normal"
            type="text"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PuiTextField
            fullWidth
            field={lastName}
            inputProps={{ maxLength: 100 }}
            label={`${lastName.label}*`}
            margin="normal"
            type="text"
          />
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          direction="column"
          justifyContent="center"
          mt={3}
        >
          <Typography className={classes.text} variant="h5">
            {t('Common:HOW_DID_YOU_HEAR_ABOUT_US')}
          </Typography>
          <Grid
            container
            item
            justifyContent="center"
            mt={3}
            spacing={chipSpacing}
          >
            {ReferralSources.map((source) => {
              const isSelected = referralSourceId.value === source.id
              return (
                <Grid item key={source.id} sm={4} xs={12}>
                  <KioskSelectableButton
                    isSelected={isSelected}
                    onClick={() => referralSourceId.setValue(source.id)}
                  >
                    {LanguageUtils.getTranslatedFieldName(source)}
                  </KioskSelectableButton>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </KioskScreen>
  )
}

export default NewClientScreen
