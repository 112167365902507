import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { all, put, takeLatest } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { ApiError, Utils } from '@pbt/pbt-ui-components'

import * as API from '../../api'
import {
  IdentifiableDocumentInstance,
  MaybeSavedDocumentInstance,
} from '../../types/entities/consentForm'
import { getErrorMessage } from '../../utils/errors'
import type { RootState } from '../index'
// @ts-ignore
import requestAPI from '../sagas/requestAPI'

export const FETCH_DOCUMENTS_LIST_V2 = 'questions/FETCH_DOCUMENTS_LIST_V2'
export const FETCH_DOCUMENTS_LIST_V2_SUCCESS =
  'questions/FETCH_DOCUMENTS_LIST_V2_SUCCESS'
export const FETCH_DOCUMENTS_LIST_V2_FAILURE =
  'questions/FETCH_DOCUMENTS_LIST_V2_FAILURE'
export const SIGN_DOCUMENT_V2 = 'questions/SIGN_DOCUMENT_V2'
export const SIGN_DOCUMENT_V2_SUCCESS = 'questions/SIGN_DOCUMENT_V2_SUCCESS'
export const SIGN_DOCUMENT_V2_FAILURE = 'questions/SIGN_DOCUMENT_V2_FAILURE'

type DocumentsV2State = {
  error: string | null
  isLoading: boolean
  list: IdentifiableDocumentInstance[]
}

const INITIAL_STATE: DocumentsV2State = {
  list: [],
  error: null,
  isLoading: false,
}

const documentsV2Slice = createSlice({
  name: 'documentsV2',
  initialState: INITIAL_STATE,
  reducers: {
    fetchDocumentsListV2: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ appointmentId: string }>,
    ) => {
      state.isLoading = true
      state.error = null
      state.list = []
    },
    fetchDocumentsListV2Success: (
      state,
      action: PayloadAction<IdentifiableDocumentInstance[]>,
    ) => {
      state.list = action.payload
      state.isLoading = false
    },
    fetchDocumentsListV2Failure: (state, action: PayloadAction<ApiError>) => {
      state.error = getErrorMessage(action.payload)
      state.isLoading = false
    },
    signDocumentV2: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ document: MaybeSavedDocumentInstance }>,
    ) => {
      state.isLoading = true
      state.error = null
    },
    signDocumentV2Success: (
      state,
      action: PayloadAction<{ document: IdentifiableDocumentInstance }>,
    ) => {
      state.list = Utils.updateById(
        { ...action.payload.document, status: 'COMPLETE' },
        state.list,
      )
      state.isLoading = false
    },
    signDocumentV2Failure: (state, action: PayloadAction<ApiError>) => {
      state.error = getErrorMessage(action.payload)
      state.isLoading = false
    },
  },
})

export const documentsV2Reducer = documentsV2Slice.reducer

export const {
  fetchDocumentsListV2,
  fetchDocumentsListV2Success,
  fetchDocumentsListV2Failure,
  signDocumentV2,
  signDocumentV2Success,
  signDocumentV2Failure,
} = documentsV2Slice.actions

export const getDocumentsV2 = (state: RootState) => state.documentsV2
export const getDocumentsV2List = (
  state: RootState,
): IdentifiableDocumentInstance[] => getDocumentsV2(state).list
export const getDocumentsV2IsLoading = (state: RootState): boolean =>
  getDocumentsV2(state).isLoading
export const getDocumentsV2Error = (state: RootState): string | null =>
  getDocumentsV2(state).error

export function* sagaFetchDocumentsListV2({
  payload: { appointmentId },
}: ReturnType<typeof fetchDocumentsListV2>) {
  try {
    const list: MaybeSavedDocumentInstance[] = yield* requestAPI(
      API.fetchDocumentsListV2,
      appointmentId,
    )
    const listWithIds: IdentifiableDocumentInstance[] = list.map((item) => {
      if ('id' in item && item.id) {
        return { ...item, generatedId: false }
      }
      return { ...item, id: uuid(), generatedId: true }
    })
    yield put(fetchDocumentsListV2Success(listWithIds))
  } catch (error) {
    yield put(fetchDocumentsListV2Failure(error as ApiError))
  }
}

export function* sagaSignDocumentV2({
  payload: { document },
}: ReturnType<typeof signDocumentV2>) {
  try {
    const result = yield* requestAPI(API.signDocumentV2, {
      ...document,
      status: 'COMPLETE',
    })
    yield put(
      signDocumentV2Success({
        document: { ...result, generatedId: false },
      }),
    )
  } catch (error) {
    yield put(signDocumentV2Failure(error as ApiError))
  }
}

function* watchFetchDocumentsList() {
  yield takeLatest(fetchDocumentsListV2.type, sagaFetchDocumentsListV2)
}

function* watchSignDocument() {
  yield takeLatest(signDocumentV2.type, sagaSignDocumentV2)
}

export function* documentsV2Saga() {
  yield all([watchFetchDocumentsList(), watchSignDocument()])
}
