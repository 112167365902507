import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { CircularProgress, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { parse } from 'query-string'
import {
  assoc,
  filter,
  flatten,
  isEmpty,
  isNil,
  map,
  mergeWithKey,
  pipe,
  prop,
  propEq,
  sortBy,
  when,
} from 'ramda'
import {
  NumberUtils,
  Patient,
  Utils,
  WellnessPlan,
} from '@pbt/pbt-ui-components'

import Features from '../../../../constants/features'
import { WellnessPlanLevels } from '../../../../constants/wellnessPlansConstants'
import { getCurrentBusinessIsOmniChannel } from '../../../../store/duck/businesses'
import { getCurrentClient } from '../../../../store/duck/clients'
import {
  getFeatureToggle,
  getWellnessPlanType,
} from '../../../../store/duck/constants'
import {
  clearMembershipSelection,
  fetchMembershipSignUpData,
  getMembershipSelection,
  getMembershipToken,
  getWellnessPlansIsFetching,
  getWellnessPlansIsLoading,
  getWellnessPlansIsSelectionStoring,
  getWellnessPlansVersion,
  SelectionType,
  updateMembershipSelection,
} from '../../../../store/duck/wellnessPlans'
import { Membership } from '../../../../types/entities/clients'
// @ts-ignore
import { useNavigateWithQueryString } from '../../../../utils'
// @ts-ignore
import { useScrollSpy } from '../../../../utils/useScrollSpy'
import KioskLinkButton from '../../../buttons/KioskLinkButton'
import KioskScreen from '../../KioskScreen'
import {
  getEnabledPlans,
  getPlanByLevel,
  useGroupedWellnessPlans,
  useTotalsPerPatient,
  // @ts-ignore
} from '../wellnessPlanUtils'
import MembershipSignUpTable from './MembershipSignUpTable'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: 'left',
      padding: theme.spacing(2, 4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 1, 0),
      },
    },
    content: {
      padding: 0,
    },
    noMembershipsText: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },
    linksContainer: {
      marginRight: theme.spacing(2),
    },
    baseFeeText: {
      alignSelf: 'flex-start',
      color: theme.colors.tabLabel,
      fontSize: '1.4rem',
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
      },
    },
    linkButton: {
      fontSize: '1.8rem',
    },
  }),
  { name: 'MembershipSignUpScreen' },
)

const MembershipSignUpScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const location = useLocation()
  const { patientId } = (location.state || {}) as { patientId?: string }
  const { token: tokenFromUrl } = parse(location.search)
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Membership'])

  const client = useSelector(getCurrentClient)
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion) || {}
  const isManualSignUpLoading = useSelector(getWellnessPlansIsLoading)
  const token = useSelector(getMembershipToken)
  const isFetching = useSelector(getWellnessPlansIsFetching)
  const selection = useSelector(getMembershipSelection)
  const isMembershipSelectionStoring = useSelector(
    getWellnessPlansIsSelectionStoring,
  )
  const WellnessPlanType = useSelector(getWellnessPlanType)
  const isCurrentBusinessOmniChannel = useSelector(
    getCurrentBusinessIsOmniChannel,
  )
  const isSuppressAddClientsAndPatientsEnabled = useSelector(
    getFeatureToggle(Features.SUPPRESS_ADD_CLIENTS_AND_PATIENTS),
  )

  const showAddPet = !(
    isCurrentBusinessOmniChannel && isSuppressAddClientsAndPatientsEnabled
  )

  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)
  const ExtraTypeId = Utils.findConstantIdByName('Extra', WellnessPlanType)

  const plans = getEnabledPlans(wellnessPlansVersion)
  const allPlans = wellnessPlansVersion?.plans || []

  const groupWellnessPlans = useGroupedWellnessPlans()
  const { packages = [], extras = [] } = groupWellnessPlans(plans)
  const memberships = sortBy(prop('member'), client?.memberships || [])

  const [scrollRef, setScrollRef] = useState()

  const { goToItem, goToNextItem, hasNextItem } = useScrollSpy(scrollRef)

  useEffect(() => {
    if (scrollRef && patientId) {
      goToItem(
        memberships.findIndex(
          (membership) => membership.patient.id === patientId,
        ),
      )
    }
  }, [scrollRef, patientId])

  const hasPackageToSignUpFor = (membership: Membership) => {
    const packagesForMembership = filter(
      pipe(prop('level'), isNil),
      membership.plans || [],
    )

    return packages.some((packageItem: { id: string }) =>
      packagesForMembership.every(({ planId }) => planId !== packageItem.id),
    )
  }

  const hasExtraToSignUpFor = (membership: Membership) => {
    const extrasForMembership = filter(
      pipe(prop('level'), isNil),
      membership.plans || [],
    )

    return extras.some((extra: { id: string }) =>
      extrasForMembership.every(({ planId }) => planId !== extra.id),
    )
  }

  const availableTierMemberships = memberships.filter(
    (membership: Membership) => !membership.member,
  )
  const availablePackageMemberships = memberships.filter(hasPackageToSignUpFor)
  const availableExtraMemberships = memberships.filter(hasExtraToSignUpFor)
  const baseLevelPlan = getPlanByLevel(WellnessPlanLevels.BASE, allPlans)
  const baseLevelPlanPrice = NumberUtils.formatMoney(baseLevelPlan?.price)
  const baseLevelPlanPriceType = baseLevelPlan?.priceType?.displayName || ''
  const planOneTimeFeeAmount = NumberUtils.formatMoney(
    wellnessPlansVersion?.oneTimeFeeAmount,
  )
  const isBaseHidden = wellnessPlansVersion.basePlanHidden

  const classes = useStyles()

  const getInitiallyCheckedTiersMap = () => {
    if (selection.length > 0) {
      return selection.reduce((acc: any, selectionItem: SelectionType) => {
        const plan = Utils.findById(selectionItem.planId, allPlans)
        const isPackage = plan?.planTypeId === PackageTypeId
        const isExtra = plan?.planTypeId === ExtraTypeId
        const isTier = !isPackage && !isExtra // for now

        if (!isTier) {
          return acc
        }

        return {
          ...acc,
          [selectionItem.patientId]: {
            planId: selectionItem.planId,
            priceTypeId: selectionItem.priceTypeId,
          },
        }
      }, {})
    }

    return availableTierMemberships.reduce(
      (acc, membership) => ({
        ...acc,
        [membership.patient.id]: {
          planId: '',
          priceTypeId: '',
        },
      }),
      {},
    )
  }

  const getInitiallyCheckedPackagesMap = () => {
    if (selection.length > 0) {
      return selection.reduce((acc: any, selectionItem: SelectionType) => {
        const plan = Utils.findById(selectionItem.planId, allPlans)
        const isPackage = plan?.planTypeId === PackageTypeId

        if (!isPackage) {
          return acc
        }

        return {
          ...acc,
          [selectionItem.patientId]: [
            ...(acc[selectionItem.patientId] || []),
            {
              planId: selectionItem.planId,
              priceTypeId: selectionItem.priceTypeId,
            },
          ],
        }
      }, {})
    }

    return availablePackageMemberships.reduce(
      (acc, membership) => ({
        ...acc,
        [membership.patient.id]: [],
      }),
      {},
    )
  }

  const getInitiallyCheckedExtrasMap = () => {
    if (selection.length > 0) {
      return selection.reduce((acc: any, selectionItem: SelectionType) => {
        const plan = Utils.findById(selectionItem.planId, allPlans)
        const isExtra = plan?.planTypeId === ExtraTypeId

        if (!isExtra) {
          return acc
        }

        return {
          ...acc,
          [selectionItem.patientId]: [
            ...(acc[selectionItem.patientId] || []),
            {
              planId: selectionItem.planId,
              priceTypeId: selectionItem.priceTypeId,
            },
          ],
        }
      }, {})
    }

    return availableExtraMemberships.reduce(
      (acc, membership) => ({
        ...acc,
        [membership.patient.id]: [],
      }),
      {},
    )
  }

  const [checkedTiersMap, setCheckedTiersMap] = useState<
    Record<string, SelectionType>
  >(getInitiallyCheckedTiersMap())
  const [checkedPackagesMap, setCheckedPackagesMap] = useState<
    Record<string, SelectionType[]>
  >(getInitiallyCheckedPackagesMap())
  const [checkedExtrasMap, setCheckedExtrasMap] = useState<
    Record<string, SelectionType[]>
  >(getInitiallyCheckedExtrasMap())

  useEffect(() => {
    if (client) {
      setCheckedTiersMap(getInitiallyCheckedTiersMap())
      setCheckedPackagesMap(getInitiallyCheckedPackagesMap())
      setCheckedExtrasMap(getInitiallyCheckedExtrasMap())
    }
  }, [client])

  useEffect(() => {
    if (client?.id && (!token || !tokenFromUrl)) {
      dispatch(fetchMembershipSignUpData(client.id))
    }
  }, [client?.id])

  const togglePlanChecked = (plan: WellnessPlan, patient: Patient) => {
    const isPackage = plan?.planTypeId === PackageTypeId
    const isExtra = plan?.planTypeId === ExtraTypeId
    const membershipForPatient = memberships.find(
      (membership) => membership.patient.id === patient.id,
    )

    if (isPackage) {
      const checkedForThisPatient = checkedPackagesMap[patient.id] || []
      const wasEnabled = checkedForThisPatient.some(
        (item: { planId: string }) => item.planId === plan.id,
      )

      setCheckedPackagesMap({
        ...checkedPackagesMap,
        [patient.id]: wasEnabled
          ? checkedForThisPatient.filter(
              (item: SelectionType) => item.planId !== plan.id,
            )
          : [
              ...checkedForThisPatient,
              {
                planId: plan.id,
                priceTypeId: plan.prices[0]?.priceTypeId,
              } as SelectionType,
            ],
      })

      const checkedTier = checkedTiersMap[patient.id]
      const hasNoSelectedTier = !isBaseHidden && !checkedTier

      if (hasNoSelectedTier && !membershipForPatient?.member) {
        // if no selected tier and is not a member already, select base
        setCheckedTiersMap({
          ...checkedTiersMap,
          [patient.id]: {
            ...checkedTiersMap[patient.id],
            planId: baseLevelPlan.id,
          },
        })
      }
    } else if (isExtra) {
      const checkedForThisPatient = checkedExtrasMap[patient.id] || []
      const wasEnabled = checkedForThisPatient.some(
        (item: { planId: string }) => item.planId === plan.id,
      )

      setCheckedExtrasMap({
        ...checkedExtrasMap,
        [patient.id]: wasEnabled
          ? checkedForThisPatient.filter(
              (item: { planId: string }) => item.planId !== plan.id,
            )
          : [
              ...checkedForThisPatient,
              {
                planId: plan.id,
                priceTypeId: plan.prices[0]?.priceTypeId,
              } as SelectionType,
            ],
      })

      const checkedTier = checkedTiersMap[patient.id]
      const hasNoSelectedTier = !isBaseHidden && !checkedTier

      if (hasNoSelectedTier && !membershipForPatient?.member) {
        // if no selected tier and is not a member already, select base
        setCheckedTiersMap({
          ...checkedTiersMap,
          [patient.id]: {
            ...checkedTiersMap[patient.id],
            planId: baseLevelPlan.id,
          },
        })
      }
    } else {
      const checkedForThisPatient = checkedTiersMap[patient.id]
      // @ts-ignore
      const shouldUncheck = !plan?.id || checkedForThisPatient === plan.id

      setCheckedTiersMap({
        ...checkedTiersMap,
        [patient.id]: {
          ...checkedTiersMap[patient.id],
          planId: shouldUncheck ? '' : plan.id,
          priceTypeId: shouldUncheck ? '' : plan.prices[0]?.priceTypeId,
        },
      })
    }
  }

  const onPlanPriceTypeChange = (
    plan: WellnessPlan,
    patient: Patient,
    priceTypeId: string,
  ) => {
    const isPackage = plan?.planTypeId === PackageTypeId
    const isExtra = plan?.planTypeId === ExtraTypeId

    if (isPackage) {
      const checkedForThisPatient = checkedPackagesMap[patient.id] || []

      setCheckedPackagesMap({
        ...checkedPackagesMap,
        [patient.id]: map(
          when(propEq('planId', plan.id), assoc('priceTypeId', priceTypeId)),
          checkedForThisPatient,
        ) as SelectionType[],
      })
    } else if (isExtra) {
      const checkedForThisPatient = checkedExtrasMap[patient.id]

      setCheckedExtrasMap({
        ...checkedExtrasMap,
        [patient.id]: map(
          when(propEq('planId', plan.id), assoc('priceTypeId', priceTypeId)),
          checkedForThisPatient,
        ) as SelectionType[],
      })
    } else {
      setCheckedTiersMap({
        ...checkedTiersMap,
        [patient.id]: {
          ...checkedTiersMap[patient.id],
          priceTypeId,
        },
      })
    }
  }

  const onAddPatientRequested = () => {
    navigateWithQueryString({
      url: '/membership/patient-details',
      options: { replace: true },
    })
  }

  const onBeforeBack = () => {
    dispatch(clearMembershipSelection())
  }

  const getTierSelection = () =>
    pipe(
      map((key: string) => ({
        patientId: key,
        planId: checkedTiersMap[key].planId,
        priceTypeId: checkedTiersMap[key].priceTypeId,
        level: Utils.findById(checkedTiersMap[key].planId, allPlans)?.level,
      })),
      filter(
        (selectionItem: SelectionType) =>
          !isNil(selectionItem.planId) && !isEmpty(selectionItem.planId),
      ),
    )(Object.keys(checkedTiersMap))

  const getPackageSelection = () =>
    pipe(
      map((key: string) =>
        (checkedPackagesMap[key] || []).map((item: SelectionType) => ({
          patientId: key,
          planId: item.planId,
          priceTypeId: item.priceTypeId,
        })),
      ),
      flatten,
      filter(
        (selectionItem: SelectionType) =>
          !isNil(selectionItem.planId) && !isEmpty(selectionItem.planId),
      ),
    )(Object.keys(checkedPackagesMap))

  const getExtraSelection = () =>
    pipe(
      map((key: string) =>
        (checkedExtrasMap[key] || []).map((item: SelectionType) => ({
          patientId: key,
          planId: item.planId,
          priceTypeId: item.priceTypeId,
        })),
      ),
      flatten,
      filter(
        (selectionItem: SelectionType) =>
          !isNil(selectionItem.planId) && !isEmpty(selectionItem.planId),
      ),
    )(Object.keys(checkedExtrasMap))

  const getSelection = () => [
    ...getTierSelection(),
    ...getPackageSelection(),
    ...getExtraSelection(),
  ]

  const totalsPerPatient = useTotalsPerPatient(getSelection(), memberships)

  const totals = Object.keys(totalsPerPatient).reduce((acc, patId) => {
    const totalsForPatient = totalsPerPatient[patId]
    return mergeWithKey(
      (key, left, right) => left + right,
      acc,
      totalsForPatient,
    )
  }, {})

  const hasNoSelectedItems = (item: { planId: string }) =>
    isNil(item.planId) || isEmpty(item.planId)

  const hasNoSelectedTiers =
    Object.values(checkedTiersMap).every(hasNoSelectedItems)
  const hasNoSelectedPackages = Object.values(checkedPackagesMap).every(
    (list) => list.every(hasNoSelectedItems),
  )
  const hasNoSelectedExtras = Object.values(checkedExtrasMap).every((list) =>
    list.every(hasNoSelectedItems),
  )
  const hasNoSelectedPlans =
    hasNoSelectedTiers && hasNoSelectedPackages && hasNoSelectedExtras

  const onProceed = () => {
    dispatch(updateMembershipSelection(getSelection()))
    navigateWithQueryString({ url: '/membership/plan-summary' })
  }

  const proceedButtonDisabled =
    hasNoSelectedPlans || isManualSignUpLoading || isMembershipSelectionStoring

  return (
    <KioskScreen
      additionalLabel={
        <Grid item xs className={classes.linksContainer} sm="auto">
          {showAddPet && (
            <KioskLinkButton
              className={classes.linkButton}
              onClick={onAddPatientRequested}
            >
              {t('Common:ADD_PET')}
            </KioskLinkButton>
          )}
          {memberships.length > 1 && (
            <KioskLinkButton
              className={classes.linkButton}
              disabled={proceedButtonDisabled}
              onClick={onProceed}
            >
              {t('Common:SKIP_ACTION')}
            </KioskLinkButton>
          )}
        </Grid>
      }
      classes={{
        content: classes.content,
        title: classes.title,
      }}
      justifyContent="flex-start"
      proceedButtonDisabled={hasNextItem ? false : proceedButtonDisabled}
      proceedButtonLabel={
        hasNextItem ? t('Common:NEXT_ACTION') : t('Common:CONTINUE_ACTION')
      }
      proceedButtonLoading={
        isMembershipSelectionStoring || isManualSignUpLoading
      }
      title={t('Common:SET_UP_YOUR_PLAN')}
      onBeforeBack={onBeforeBack}
      onProceed={hasNextItem ? goToNextItem : onProceed}
    >
      {isFetching ? (
        <CircularProgress />
      ) : availableTierMemberships.length === 0 &&
        availablePackageMemberships.length === 0 &&
        availableExtraMemberships.length === 0 ? (
        <Typography className={classes.noMembershipsText}>
          {t('Membership:IT_LOOKS_LIKE_YOU_DO_NOT_HAVE_ANY_PETS_TO_SIGN_UP')}
        </Typography>
      ) : (
        <>
          {baseLevelPlan?.price > 0 && (
            <Typography className={classes.baseFeeText}>
              {wellnessPlansVersion?.oneTimeFeeAmount
                ? t('Membership:EACH_SIGN_UP_HAS_BASE_AND_OTF_FEE_OF', {
                    planPrice: `${baseLevelPlanPrice}/${baseLevelPlanPriceType}`,
                    planOneTimeFeeAmount,
                  })
                : t('Membership:EACH_SIGN_UP_HAS_BASE_FEE_OF', {
                    planPrice: `${baseLevelPlanPrice}/${baseLevelPlanPriceType}`,
                  })}
            </Typography>
          )}
          <MembershipSignUpTable
            checkedExtrasMap={checkedExtrasMap}
            checkedPackagesMap={checkedPackagesMap}
            checkedTiersMap={checkedTiersMap}
            // @ts-ignore
            scrollRef={setScrollRef}
            togglePlanChecked={togglePlanChecked}
            totals={totals}
            wellnessPlanVersion={wellnessPlansVersion}
            onPlanPriceTypeChange={onPlanPriceTypeChange}
          />
        </>
      )}
    </KioskScreen>
  )
}

export default MembershipSignUpScreen
