import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  CityTextField,
  CountryCode,
  CountrySelect,
  PuiTextField,
  RegionUtils,
  StateSelect,
  Text,
  useFields,
  ZipInput,
} from '@pbt/pbt-ui-components'

import { getCurrentBusiness } from '../../store/duck/businesses'
import {
  editClient,
  getClientsIsLoading,
  getCurrentClient,
  getIsCreatingClient,
} from '../../store/duck/clients'
import { getIsCheckInFlow, getIsJoinWaitlistFlow } from '../../store/duck/flow'
import { getPatientsIsLoading } from '../../store/duck/patients'
import {
  checkInForAppointment,
  getCurrentAppointment,
} from '../../store/duck/schedules'
import { useNavigateWithQueryString } from '../../utils'
import { isPatientCheckedIn } from '../../utils/isPatientCheckedIn'
import useCallbackWhenLoaded from '../../utils/useCallbackWhenLoaded'
import KioskScreen from './KioskScreen'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  }),
  { name: 'ClientAddressScreen' },
)

const ClientAddressScreen = () => {
  const navigateWithQueryString = useNavigateWithQueryString()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')

  const client = useSelector(getCurrentClient)
  const isLoading = useSelector(getClientsIsLoading)
  const isCreate = useSelector(getIsCreatingClient)
  const isCheckIn = useSelector(getIsCheckInFlow)
  const isWaitlist = useSelector(getIsJoinWaitlistFlow)
  const business = useSelector(getCurrentBusiness)
  const appointment = useSelector(getCurrentAppointment)
  const clientDefaultCountry = client?.country || business.countryCatalogCode

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const rowSpacing = isMobile ? 0 : 3
  const spacing = isMobile ? 1 : 3

  const {
    fields: { address, suite, city, country, state, zip, nif },
    validate,
  } = useFields(
    [
      {
        name: 'address',
        label: t('Common:STREET_ADDRESS'),
        initialValue: client?.address,
      },
      {
        name: 'suite',
        label: t('Common:APARTMENT_NUMBER'),
        initialValue: client?.suite,
      },
      { name: 'city', initialValue: client?.city },
      {
        name: 'country',
        initialValue: RegionUtils.getAvailableCountry(clientDefaultCountry),
      },
      { name: 'state', type: 'select', initialValue: client?.state },
      {
        name: 'zip',
        label: t('Common:ZIP_CODE'),
        validators: ['zip'],
        initialValue: client?.zip,
      },
      {
        name: 'nif',
        label: t('Common:NIF_NUMBER'),
        initialValue: client.taxIdentificationNumber,
      },
    ],
    false,
  )

  const onCheckedIn = () => {
    navigateWithQueryString({ url: '/success' })
  }

  const callbackWhenLoadedAppointment = useCallbackWhenLoaded(
    onCheckedIn,
    getPatientsIsLoading,
  )

  const onClientEdited = () => {
    if (client.patients.length === 0) {
      navigateWithQueryString({ url: '/patient-details' })
      return
    }

    if (isPatientCheckedIn(isWaitlist, isCheckIn, appointment)) {
      callbackWhenLoadedAppointment()
      dispatch(checkInForAppointment(appointment?.id, true))
    } else if (isCheckIn && !appointment.isCheckedIn) {
      navigateWithQueryString({ url: '/any-more-pets' })
    } else if (isWaitlist) {
      navigateWithQueryString({ url: '/any-more-pets' })
    } else {
      navigateWithQueryString({ url: '/select-patient' })
    }
  }

  const callbackWhenEdited = useCallbackWhenLoaded(
    onClientEdited,
    getClientsIsLoading,
  )

  const onProceed = () => {
    if (validate()) {
      const newClient = {
        ...client,
        address: address.value,
        suite: suite.value,
        city: city.value,
        state: state.value,
        zip: zip.value,
        country: country.value,
        patients: null,
        coparents: null,
        taxIdentificationNumber: nif.value,
      }

      callbackWhenEdited()
      dispatch(editClient(newClient))
    }
  }

  const onSetCountry = (event) => {
    zip.setValue('')
    state.setValue('')
    country.set(event)
  }

  return (
    <KioskScreen
      hideBackButton
      justifyContent="flex-start"
      proceedButtonLoading={isLoading}
      title={
        isCreate
          ? t('Common:WHAT_IS_YOUR_ADDRESS')
          : t('Common:ARE_YOU_STILL_AT_THIS_ADDRESS')
      }
      onProceed={onProceed}
    >
      <Grid
        container
        item
        className={classes.root}
        direction="column"
        spacing={rowSpacing}
        wrap="nowrap"
      >
        <Grid container item spacing={spacing}>
          <Grid item md={9} xs={12}>
            <PuiTextField
              field={address}
              inputProps={{ maxLength: 100 }}
              label={address.label}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <PuiTextField
              field={suite}
              inputProps={{ maxLength: 100 }}
              label={suite.label}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="flex-end" spacing={spacing}>
          <Grid item sm xs={12}>
            <CityTextField country={country.value} field={city} />
          </Grid>
          <Grid item sm xs={12}>
            <CountrySelect field={{ ...country, set: onSetCountry }} />
          </Grid>
          <Grid item xs>
            <StateSelect country={country.value} field={state} />
          </Grid>
          <Grid item xs>
            <PuiTextField
              InputProps={{
                inputComponent: ZipInput,
              }}
              field={zip}
              label={zip.label}
            />
          </Grid>
        </Grid>
        {business?.countryCatalogCode === CountryCode.ES && (
          <>
            <Text
              strong
              align={isMobile ? 'left' : 'center'}
              pt={3}
              variant={isMobile ? 'subheading2' : 'hero'}
            >
              {t('Common:TAX_INFORMATION')}
            </Text>
            <Grid container justifyContent="center">
              <Grid item md={6} xs={12}>
                <PuiTextField field={nif} label={nif.label} />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </KioskScreen>
  )
}

export default ClientAddressScreen
