import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ClickAwayListener, Collapse, Grid, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { Text } from '@pbt/pbt-ui-components'
import { ChevronDown, ChevronUp } from '@pbt/pbt-ui-components/src/icons'
import { FallbackLanguageCode } from '@pbt/pbt-ui-components/src/localization'

import i18nKiosk from '../locales/i18n'
import { getCurrentBusinessLanguageCodes } from '../store/duck/businesses'
import { getLanguages } from '../store/duck/constants'
import { useChangeLanguage } from '../utils/useChangeLanguage'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.badgeColor,
      cursor: 'pointer',
      border: theme.constants.inactiveBorder,
      borderRadius: 28,
    },
    chevronIcon: {
      width: 20,
      height: 20,
      color: theme.colors.lowAccentText,
      marginLeft: theme.spacing(1),
    },
    collapse: {
      boxShadow: theme.constants.blockShadow,
      position: 'absolute',
      backgroundColor: theme.colors.badgeColor,
      left: 0,
      top: 'calc(100% + 1px)',
      borderRadius: 5,
      minWidth: '100%',
    },
    currentLanguage: {
      whiteSpace: 'nowrap',
    },
    menuItem: {
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: theme.colors.listItemHover,
      },
    },
  }),
  { name: 'LanguageSelector' },
)

const LanguageSelector = () => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { toggleLang } = useChangeLanguage()

  const Languages = useSelector(getLanguages)
  const currentBusinessLanguageCodes = useSelector(
    getCurrentBusinessLanguageCodes,
  )
  const currentBusinessLanguagesLength =
    currentBusinessLanguageCodes?.length || 0

  const [expanded, setExpanded] = useState(false)

  if (currentBusinessLanguagesLength <= 1) {
    return null
  }

  const IconChevronComponent = expanded ? ChevronUp : ChevronDown

  const textVariant = isMobile ? 'lowAccent2' : 'lowAccent'

  const LanguageCodeNames = R.indexBy(R.prop('code'), Languages)

  const handleChangeLang = (code) => {
    setExpanded(false)
    toggleLang(code)
  }

  return (
    <ClickAwayListener
      mouseEvent="onMouseUp"
      onClickAway={() => setExpanded(false)}
    >
      <Grid
        container
        alignItems="center"
        className={classes.container}
        position="relative"
        px={2}
        py={1.5}
        wrap="nowrap"
        onClick={() => setExpanded(!expanded)}
      >
        <Grid container item alignItems="center" wrap="nowrap">
          <Text
            strong
            className={classes.currentLanguage}
            variant={textVariant}
          >
            {LanguageCodeNames[i18nKiosk.language]?.fullName ||
              LanguageCodeNames[FallbackLanguageCode]?.fullName}
          </Text>
        </Grid>
        <IconChevronComponent className={classes.chevronIcon} />
        <Collapse
          mountOnEnter
          unmountOnExit
          className={classes.collapse}
          in={expanded}
        >
          {currentBusinessLanguageCodes.map((code) => (
            <Text
              strong
              className={classes.menuItem}
              key={code}
              px={1}
              py={0.5}
              variant={textVariant}
              onClick={() => handleChangeLang(code)}
            >
              {LanguageCodeNames[code]?.fullName}
            </Text>
          ))}
        </Collapse>
      </Grid>
    </ClickAwayListener>
  )
}

export default LanguageSelector
