import { AnyAction } from 'redux'
import { all, put, takeLatest } from 'redux-saga/effects'
import { ApiError, Nil } from '@pbt/pbt-ui-components'

import * as API from '../../api'
import { KioskClient } from '../../types/entities/clients'
import type { RootState } from '..'
// @ts-ignore
import requestAPI from '../sagas/requestAPI'
import { updateCurrentBusiness } from './businesses'

export const FETCH_CLIENT_BY_PHONE = 'clients/FETCH_CLIENT_BY_PHONE'
export const FETCH_CLIENT_BY_PHONE_SUCCESS =
  'clients/FETCH_CLIENT_BY_PHONE_SUCCESS'
export const FETCH_CLIENT_BY_PHONE_FAILURE =
  'clients/FETCH_CLIENT_BY_PHONE_FAILURE'

export const FETCH_CLIENT_BY_EMAIL = 'clients/FETCH_CLIENT_BY_EMAIL'
export const FETCH_CLIENT_BY_EMAIL_SUCCESS =
  'clients/FETCH_CLIENT_BY_EMAIL_SUCCESS'
export const FETCH_CLIENT_BY_EMAIL_FAILURE =
  'clients/FETCH_CLIENT_BY_EMAIL_FAILURE'

export const FETCH_CLIENT_BY_ID = 'clients/FETCH_CLIENT_BY_ID'
export const FETCH_CLIENT_BY_ID_SUCCESS = 'clients/FETCH_CLIENT_BY_ID_SUCCESS'
export const FETCH_CLIENT_BY_ID_FAILURE = 'clients/FETCH_CLIENT_BY_ID_FAILURE'

export const CREATE_CLIENT = 'clients/CREATE_CLIENT'
export const CREATE_CLIENT_SUCCESS = 'clients/CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FAILURE = 'clients/CREATE_CLIENT_FAILURE'

export const EDIT_CLIENT = 'clients/EDIT_CLIENT'
export const EDIT_CLIENT_SUCCESS = 'clients/EDIT_CLIENT_SUCCESS'
export const EDIT_CLIENT_FAILURE = 'clients/EDIT_CLIENT_FAILURE'

export const UPDATE_CURRENT_CLIENT = 'clients/UPDATE_CURRENT_CLIENT'
export const SET_IS_CREATING_CLIENT = 'clients/SET_IS_CREATING_CLIENT'
export const CLEAR_CURRENT_CLIENT = 'clients/CLEAR_CURRENT_CLIENT'

export const SET_CLIENT_ALREADY_EXISTS = 'clients/SET_CLIENT_ALREADY_EXISTS'

export const VERIFY_CLIENT_EMAIL_FOR_BOOP =
  'clients/VERIFY_CLIENT_EMAIL_FOR_BOOP'
export const VERIFY_CLIENT_EMAIL_FOR_BOOP_SUCCESS =
  'clients/VERIFY_CLIENT_EMAIL_FOR_BOOP_SUCCESS'
export const VERIFY_CLIENT_EMAIL_FOR_BOOP_FAILURE =
  'clients/VERIFY_CLIENT_EMAIL_FOR_BOOP_FAILURE'

export const VERIFY_CLIENT_EMAIL_BY_TOKEN =
  'clients/VERIFY_CLIENT_EMAIL_BY_TOKEN'
export const VERIFY_CLIENT_EMAIL_BY_TOKEN_SUCCESS =
  'clients/VERIFY_CLIENT_EMAIL_BY_TOKEN_SUCCESS'
export const VERIFY_CLIENT_EMAIL_BY_TOKEN_FAILURE =
  'clients/VERIFY_CLIENT_EMAIL_BY_TOKEN_FAILURE'

export const VALIDATE_CLIENT_EMAIL = 'clients/VALIDATE_CLIENT_EMAIL'
export const VALIDATE_CLIENT_EMAIL_SUCCESS =
  'clients/VALIDATE_CLIENT_EMAIL_SUCCESS'
export const VALIDATE_CLIENT_EMAIL_FAILURE =
  'clients/VALIDATE_CLIENT_EMAIL_FAILURE'

export const CLEAR_EMAIL_VERIFY_DATA = 'clients/CLEAR_EMAIL_VERIFY_DATA'

export const fetchClientByPhone = (
  phoneNumber: string,
  appointmentId?: string,
  withGlobalPreferences = false,
) => ({
  type: FETCH_CLIENT_BY_PHONE,
  phoneNumber,
  appointmentId,
  withGlobalPreferences,
})
export const fetchClientByPhoneSuccess = (client: KioskClient) => ({
  type: FETCH_CLIENT_BY_PHONE_SUCCESS,
  client,
})
export const fetchClientByPhoneFailure = (error: ApiError) => ({
  type: FETCH_CLIENT_BY_PHONE_FAILURE,
  error,
})

export const fetchClientByEmail = (email: string, appointmentId?: string) => ({
  type: FETCH_CLIENT_BY_EMAIL,
  email,
  appointmentId,
})
export const fetchClientByEmailSuccess = (client: KioskClient) => ({
  type: FETCH_CLIENT_BY_EMAIL_SUCCESS,
  client,
})
export const fetchClientByEmailFailure = (error: ApiError) => ({
  type: FETCH_CLIENT_BY_EMAIL_FAILURE,
  error,
})

export const fetchClientById = (clientId: string, appointmentId: string) => ({
  type: FETCH_CLIENT_BY_ID,
  clientId,
  appointmentId,
})
export const fetchClientByIdSuccess = (client: KioskClient) => ({
  type: FETCH_CLIENT_BY_ID_SUCCESS,
  client,
})
export const fetchClientByIdFailure = (error: ApiError) => ({
  type: FETCH_CLIENT_BY_ID_FAILURE,
  error,
})

export const createClient = (client: KioskClient, forceExisting?: boolean) => ({
  type: CREATE_CLIENT,
  client,
  forceExisting,
})
export const createClientSuccess = (client: KioskClient) => ({
  type: CREATE_CLIENT_SUCCESS,
  client,
})
export const createClientFailure = (error: ApiError) => ({
  type: CREATE_CLIENT_FAILURE,
  error,
})

export const editClient = (client: KioskClient) => ({
  type: EDIT_CLIENT,
  client,
})
export const editClientSuccess = (client: KioskClient) => ({
  type: EDIT_CLIENT_SUCCESS,
  client,
})
export const editClientFailure = (error: ApiError) => ({
  type: EDIT_CLIENT_FAILURE,
  error,
})

export const updateCurrentClient = (client: Partial<KioskClient>) => ({
  type: UPDATE_CURRENT_CLIENT,
  client,
})
export const setIsCreatingClient = (isCreating: boolean) => ({
  type: SET_IS_CREATING_CLIENT,
  isCreating,
})
export const clearCurrentClient = () => ({ type: CLEAR_CURRENT_CLIENT })

export const setClientAlreadyExists = (
  exists: boolean,
  description?: string,
) => ({
  type: SET_CLIENT_ALREADY_EXISTS,
  exists,
  description,
})

export const verifyClientEmailForBoop = (
  clientId: string,
  email: string,
  isOmniChannel: boolean,
) => ({
  type: VERIFY_CLIENT_EMAIL_FOR_BOOP,
  clientId,
  email,
  isOmniChannel,
})
export const verifyClientEmailForBoopSuccess = (data: any) => ({
  type: VERIFY_CLIENT_EMAIL_FOR_BOOP_SUCCESS,
  data,
})
export const verifyClientEmailForBoopFailure = (error: ApiError) => ({
  type: VERIFY_CLIENT_EMAIL_FOR_BOOP_FAILURE,
  error,
})

export const verifyClientEmailByToken = (
  clientId: string,
  token: string | undefined,
) => ({
  type: VERIFY_CLIENT_EMAIL_BY_TOKEN,
  clientId,
  token,
})
export const verifyClientEmailByTokenSuccess = () => ({
  type: VERIFY_CLIENT_EMAIL_BY_TOKEN_SUCCESS,
})
export const verifyClientEmailByTokenFailure = (error: ApiError) => ({
  type: VERIFY_CLIENT_EMAIL_BY_TOKEN_FAILURE,
  error,
})

export const validateClientEmail = (clientId: string) => ({
  type: VALIDATE_CLIENT_EMAIL,
  clientId,
})
export const validateClientEmailSuccess = (client: Partial<KioskClient>) => ({
  type: VALIDATE_CLIENT_EMAIL_SUCCESS,
  client,
})
export const validateClientEmailFailure = (error: ApiError) => ({
  type: VALIDATE_CLIENT_EMAIL_FAILURE,
  error,
})

export const clearEmailVerifyData = () => ({ type: CLEAR_EMAIL_VERIFY_DATA })

type ClientsState = {
  clientAlreadyExists: boolean
  clientAlreadyExistsMessage: string | Nil
  currentClient: KioskClient | null
  emailVerifyData: {}
  error: string | null
  isCreating: boolean
  isLoading: boolean
}

const INITIAL_STATE: ClientsState = {
  clientAlreadyExists: false,
  clientAlreadyExistsMessage: '',
  isCreating: false,
  currentClient: null,
  error: null,
  isLoading: false,
  emailVerifyData: {},
}

export const clientsReducer = (
  state = INITIAL_STATE,
  action: AnyAction,
): ClientsState => {
  switch (action.type) {
    case FETCH_CLIENT_BY_PHONE:
    case FETCH_CLIENT_BY_EMAIL:
    case FETCH_CLIENT_BY_ID:
    case EDIT_CLIENT:
    case CREATE_CLIENT:
    case VALIDATE_CLIENT_EMAIL:
      return { ...state, isLoading: true }
    case FETCH_CLIENT_BY_PHONE_SUCCESS:
    case FETCH_CLIENT_BY_EMAIL_SUCCESS:
    case FETCH_CLIENT_BY_ID_SUCCESS:
    case EDIT_CLIENT_SUCCESS:
    case VALIDATE_CLIENT_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentClient: { ...state.currentClient, ...action.client },
      }
    case EDIT_CLIENT_FAILURE:
    case VALIDATE_CLIENT_EMAIL_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case FETCH_CLIENT_BY_PHONE_FAILURE:
    case FETCH_CLIENT_BY_EMAIL_FAILURE:
    case FETCH_CLIENT_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        currentClient: null,
      }
    case CLEAR_CURRENT_CLIENT:
      return { ...state, error: null, currentClient: null, isCreating: false }
    case UPDATE_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: { ...state.currentClient, ...action.client },
      }
    case SET_IS_CREATING_CLIENT:
      return { ...state, isCreating: action.isCreating }
    case CREATE_CLIENT_SUCCESS:
      return { ...state, isLoading: false, currentClient: action.client }
    case CREATE_CLIENT_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case SET_CLIENT_ALREADY_EXISTS:
      return {
        ...state,
        clientAlreadyExists: action.exists,
        clientAlreadyExistsMessage: action.description,
      }
    case VERIFY_CLIENT_EMAIL_FOR_BOOP:
      return { ...state, isLoading: true }
    case VERIFY_CLIENT_EMAIL_FOR_BOOP_SUCCESS:
      return { ...state, isLoading: false, emailVerifyData: action.data || {} }
    case VERIFY_CLIENT_EMAIL_FOR_BOOP_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case VERIFY_CLIENT_EMAIL_BY_TOKEN:
      return { ...state, error: null, isLoading: true }
    case VERIFY_CLIENT_EMAIL_BY_TOKEN_SUCCESS:
      return { ...state, isLoading: false }
    case VERIFY_CLIENT_EMAIL_BY_TOKEN_FAILURE:
      return { ...state, error: action.error, isLoading: false }
    case CLEAR_EMAIL_VERIFY_DATA:
      return { ...state, emailVerifyData: {} }
    default:
      return state
  }
}

export const getClients = (state: RootState): ClientsState => state.clients
export const getCurrentClient = (state: RootState) =>
  getClients(state).currentClient
export const getClientsIsLoading = (state: RootState) =>
  getClients(state).isLoading
export const getClientsError = (state: RootState) => getClients(state).error
export const getIsCreatingClient = (state: RootState) =>
  getClients(state).isCreating
export const getClientAlreadyExists = (state: RootState) =>
  getClients(state).clientAlreadyExists
export const getClientAlreadyExistsMessage = (state: RootState) =>
  getClients(state).clientAlreadyExistsMessage
export const getClientEmailVerifyData = (state: RootState) =>
  getClients(state).emailVerifyData

export function* sagaFetchClientByPhone({
  phoneNumber,
  appointmentId,
  withGlobalPreferences,
}: ReturnType<typeof fetchClientByPhone>) {
  try {
    const client = yield* requestAPI(
      API.fetchClientByPhone,
      phoneNumber,
      appointmentId,
      withGlobalPreferences,
    )
    yield put(fetchClientByPhoneSuccess(client))
  } catch (error) {
    yield put(fetchClientByPhoneFailure(error as ApiError))
  }
}

export function* sagaFetchClientByEmail({
  email,
  appointmentId,
}: ReturnType<typeof fetchClientByEmail>) {
  try {
    const client = yield* requestAPI(
      API.fetchClientByEmail,
      email,
      appointmentId,
    )
    yield put(fetchClientByEmailSuccess(client))
  } catch (error) {
    yield put(fetchClientByEmailFailure(error as ApiError))
  }
}

export function* sagaFetchClientById({
  clientId,
  appointmentId,
}: ReturnType<typeof fetchClientById>) {
  try {
    const client = yield* requestAPI(
      API.fetchClientById,
      clientId,
      appointmentId,
    )
    yield put(fetchClientByIdSuccess(client))
  } catch (error) {
    yield put(fetchClientByIdFailure(error as ApiError))
  }
}

export function* sagaEditClient({ client }: ReturnType<typeof editClient>) {
  try {
    const result = yield* requestAPI(API.editClient, client)
    yield put(editClientSuccess(result))
    if (client.email) {
      yield put(validateClientEmail(result.id))
    }
  } catch (error) {
    yield put(editClientFailure(error as ApiError))
  }
}

export function* sagaCreateClient({
  client,
  forceExisting = false,
}: ReturnType<typeof createClient>) {
  try {
    const { entity } = yield* requestAPI(
      API.createClient,
      client,
      forceExisting,
    )
    yield put(createClientSuccess(entity))
    if (client.email) {
      yield put(validateClientEmail(entity.id))
    }
  } catch (error) {
    const { status, message } = error as ApiError
    if (status === 409) {
      yield put(setClientAlreadyExists(true, message))
    }

    yield put(createClientFailure(error as ApiError))
  }
}

export function* sagaVerifyClientEmailForBoop({
  clientId,
  email,
  isOmniChannel,
}: ReturnType<typeof verifyClientEmailForBoop>) {
  try {
    // Omnichannel does not do Boop validation
    const result = isOmniChannel
      ? { valid: true }
      : yield* requestAPI(API.verifyClientEmailForBoop, clientId, email)
    yield put(verifyClientEmailForBoopSuccess(result))
  } catch (error) {
    yield put(verifyClientEmailForBoopFailure(error as ApiError))
  }
}

export function* sagaVerifyClientEmailByToken({
  clientId,
  token,
}: ReturnType<typeof verifyClientEmailByToken>) {
  try {
    const { error, data: currentBusiness } = yield* requestAPI(
      API.verifyClientEmailByToken,
      clientId,
      token,
    )
    yield put(updateCurrentBusiness(currentBusiness))
    if (error) {
      yield put(verifyClientEmailByTokenFailure(error))
    } else {
      yield put(verifyClientEmailByTokenSuccess())
    }
  } catch (error) {
    yield put(verifyClientEmailByTokenFailure(error as ApiError))
  }
}

export function* sagaValidateClientEmail({
  clientId,
}: ReturnType<typeof validateClientEmail>) {
  try {
    const { type } = yield* requestAPI(API.validateClientEmail, clientId)

    yield put(
      validateClientEmailSuccess({
        id: clientId,
        emailValidationResultType: type,
      }),
    )
  } catch (error) {
    yield put(validateClientEmailFailure(error as ApiError))
  }
}

function* watchFetchClientByPhone() {
  yield takeLatest(FETCH_CLIENT_BY_PHONE, sagaFetchClientByPhone)
}

function* watchFetchClientByEmail() {
  yield takeLatest(FETCH_CLIENT_BY_EMAIL, sagaFetchClientByEmail)
}

function* watchFetchClientById() {
  yield takeLatest(FETCH_CLIENT_BY_ID, sagaFetchClientById)
}

function* watchEditClient() {
  yield takeLatest(EDIT_CLIENT, sagaEditClient)
}

function* watchCreateClient() {
  yield takeLatest(CREATE_CLIENT, sagaCreateClient)
}

function* watchVerifyClientEmailForBoop() {
  yield takeLatest(VERIFY_CLIENT_EMAIL_FOR_BOOP, sagaVerifyClientEmailForBoop)
}

function* watchVerifyClientEmailByToken() {
  yield takeLatest(VERIFY_CLIENT_EMAIL_BY_TOKEN, sagaVerifyClientEmailByToken)
}

function* watchValidateClientEmail() {
  yield takeLatest(VALIDATE_CLIENT_EMAIL, sagaValidateClientEmail)
}

export function* clientsSaga() {
  yield all([
    watchFetchClientByPhone(),
    watchFetchClientByEmail(),
    watchFetchClientById(),
    watchEditClient(),
    watchCreateClient(),
    watchVerifyClientEmailForBoop(),
    watchVerifyClientEmailByToken(),
    watchValidateClientEmail(),
  ])
}
