import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Text } from '@pbt/pbt-ui-components'

import { getIsAuthorize } from '../../../store/duck/finance'
import KioskButtonWithLoader from '../../buttons/KioskButtonWithLoader'

const useStyles = makeStyles(
  () => ({
    buttonContainer: {
      marginTop: 1,
      borderRadius: '0 0 4px 4px',
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
    },
    button: {
      width: 310,
    },
  }),
  { name: 'PayButtonSection' },
)

const PayButtonSection = ({
  onProceed,
  alignment,
  buttonTextOverride,
  disabled,
}) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Payment'])

  const isAuthorize = useSelector(getIsAuthorize)
  const alignmentMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }

  return (
    <Grid
      container
      item
      alignItems={alignmentMap[alignment] ?? 'center'}
      className={classes.buttonContainer}
      direction="column"
      justifyContent="center"
      px={3}
      py={2}
    >
      {isAuthorize && (
        <Text mb={1} variant="body2">
          {t(
            'Payment:YOUR_PRACTICE_IS_ONLY_REQUESTING_HOLD_ON_YOUR_CREDIT_CARD',
          )}
          .
        </Text>
      )}
      <KioskButtonWithLoader
        className={classes.button}
        disabled={disabled}
        onClick={onProceed}
      >
        {buttonTextOverride ||
          (isAuthorize ? t('Common:AUTHORIZE_CHARGE') : t('Common:PAY_NOW'))}
      </KioskButtonWithLoader>
    </Grid>
  )
}

export default PayButtonSection
