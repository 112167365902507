import { useSelector } from 'react-redux'

import { getCurrentBusinessHasOpenBoop } from '../store/duck/businesses'

const useBoopEnabled = () => {
  const openBoop = useSelector(getCurrentBusinessHasOpenBoop)

  return openBoop
}

export default useBoopEnabled
