import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import {
  flatten,
  includes,
  is,
  map,
  partition,
  pluck,
  sort,
  uniqBy,
} from 'ramda'
import {
  ClassesType,
  moment,
  Patient,
  PuiSelect,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

// @ts-ignore
import { MultidayAppointmentTypesList } from '../../../constants/appointmentConstants'
import { getAppointmentTypesMap } from '../../../store/duck/appointmentTypes'
// @ts-ignore
import { getCurrentClient } from '../../../store/duck/clients'
import { getAppointmentTypes } from '../../../store/duck/constants'
// @ts-ignore
import { getCurrentPatient } from '../../../store/duck/patients'
import {
  fetchAvailableStuff,
  getAvailableStuff,
  getIsFetchingStuff,
  getSchedulesWorkingHours,
} from '../../../store/duck/schedules'
import { KioskClient } from '../../../types/entities/clients'
import { AvailableStuff } from '../../../types/entities/schedules'
// @ts-ignore
import KioskMomentUtils from '../../../utils/KioskAdapterMoment'
// @ts-ignore
import KioskCalendar from '../../inputs/KioskCalendar'
// @ts-ignore
import CalendarSectionSlotList from './CalendarSectionSlotList'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flex: 1,
    },
    content: {
      flex: 1,
      height: '100%',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    title: {
      padding: theme.spacing(3, 5, 0),
      fontSize: '2.6rem',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        fontSize: '1.6rem',
        padding: theme.spacing(2),
      },
    },
    calendarContainer: {
      height: 360,
      minWidth: 380,
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(3),
      },
      borderRadius: 8,
      backgroundColor: theme.colors.tableBackground,
      boxShadow: '0 1px 4px 0 rgba(60,56,56,0.2)',
    },
    slotsContainer: {
      margin: 0,
      minWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: 360,
        flex: 1,
        overflowY: 'auto',
        maxHeight: 'calc(var(--app-height) - 92px - 440px)',
      },
    },
    doctorSelect: {
      marginBottom: theme.spacing(2),
    },
    text: {
      marginTop: theme.spacing(3),
      fontSize: '1.6rem',
    },
  }),
  { name: 'CalendarSection' },
)

const Steps = {
  SELECT_START_SLOT: 'SELECT_START_SLOT',
  SELECT_END_SLOT: 'SELECT_END_SLOT',
}

// leave only slots that are after current time
// and add doctorId to each slot
const processSlots = (doctorItems: AvailableStuff[]) => {
  const processedItems = doctorItems.map((item) => ({
    ...item,
    timeSlots: item.timeSlots.map((slot) => ({
      ...slot,
      doctorId: item.personInfo.id,
    })),
  }))

  const slots = flatten(pluck('timeSlots', processedItems))
  const uniqueSlots = uniqBy((slot) => `${slot.from}${slot.to}`, slots)

  return uniqueSlots.filter((slot) => moment(slot.from).isSameOrAfter(moment()))
}
type SelectedSlot = {
  doctorId: string
  from: number
  to: number
}

export interface CalendarSectionHandle {
  onBack: () => void
  onProceed: () => void
}

interface CalendarSectionProps {
  readonly appointment: any
  readonly classes?: ClassesType<typeof useStyles>
  readonly onBack: () => void
  readonly onCanProceedChange: (arg: boolean) => void
  readonly onFinalize: (arg: any) => void
}

const CalendarSection = forwardRef<CalendarSectionHandle, CalendarSectionProps>(
  function CalendarSection(
    {
      appointment,
      onFinalize,
      onBack,
      onCanProceedChange,
      classes: classesProp,
    },
    ref,
  ) {
    const dispatch = useDispatch()
    const { t } = useTranslation('Common')

    const classes = useStyles({ classes: classesProp })
    const workingHours = useSelector(getSchedulesWorkingHours)
    const AppointmentTypes = useSelector(getAppointmentTypes)
    const appointmentTypesMap = useSelector(getAppointmentTypesMap)
    const availableStuff = useSelector(getAvailableStuff)
    const isLoading = useSelector(getIsFetchingStuff)
    const patient = useSelector(getCurrentPatient) as Patient
    const client = useSelector(getCurrentClient) as KioskClient

    const { id, personRoles, personResponsibilities } = appointment || {}

    const appointmentTypeId = is(Object, appointment.type)
      ? appointment?.businessAppointmentType?.id
      : appointment.type

    const eventTypeId = is(Object, appointment.type)
      ? appointment?.businessAppointmentType?.evetTypeId
      : appointmentTypesMap[appointment.type]?.eventTypeId

    const fetchData = (selectedDate: string) => {
      dispatch(
        fetchAvailableStuff(
          appointmentTypeId,
          patient?.id,
          client?.id,
          selectedDate,
          id,
        ),
      )
    }

    const isMobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('sm'),
    )
    const multidayTypes = map(
      (name) => Utils.findConstantIdByName(name, AppointmentTypes),
      MultidayAppointmentTypesList,
    )

    const getInitialStartDate = () => moment().add(1, 'd').toISOString()
    const getInitialEndDate = () => moment().add(2, 'd').toISOString()

    const [step, setStep] = useState(Steps.SELECT_START_SLOT)
    const [selectedStartDate, setSelectedStartDate] = useState(
      getInitialStartDate(),
    )
    const [selectedEndDate, setSelectedEndDate] = useState(getInitialEndDate())
    const [selectedStartSlot, setSelectedStartSlot] = useState<SelectedSlot>()
    const [selectedEndSlot, setSelectedEndSlot] = useState<
      SelectedSlot | undefined
    >()
    const [selectedDoctorId, setSelectedDoctorId] = useState(
      personResponsibilities?.[0]?.personId || personRoles?.[0]?.personId || '',
    )

    const availableSlotsWithStuff = availableStuff

    const isMultiday = includes(eventTypeId, multidayTypes)
    const isSelectStartSlotStep = step === Steps.SELECT_START_SLOT
    const isSelectEndSlotStep = step === Steps.SELECT_END_SLOT

    const selectedDateMap = {
      [Steps.SELECT_START_SLOT]: [
        selectedStartDate,
        setSelectedStartDate,
        selectedStartSlot,
        setSelectedStartSlot,
      ],
      [Steps.SELECT_END_SLOT]: [
        selectedEndDate,
        setSelectedEndDate,
        selectedEndSlot,
        setSelectedEndSlot,
      ],
    }

    const titleMap = {
      [Steps.SELECT_START_SLOT]: isMultiday
        ? t('Common:SELECT_CHECK_IN_DATE_AND_TIME')
        : t('Common:SELECT_AVAILABLE_SLOT'),
      [Steps.SELECT_END_SLOT]: t('Common:SELECT_CHECK_OUT_DATE_AND_TIME'),
    }

    const [selectedDate, setSelectedDate, selectedSlot, setSelectedSlot] =
      selectedDateMap[step]

    useEffect(() => {
      // @ts-ignore
      setSelectedSlot()
      fetchData(selectedDate as string)
    }, [step, selectedDate])

    useEffect(() => {
      // @ts-ignore
      setSelectedSlot()
    }, [selectedDoctorId])

    const doctors = availableStuff
      .map((item) => ({
        id: item.personInfo?.id,
        name: Utils.getPersonString(item.personInfo),
      }))
      .filter((item) => item.id)

    const [[selectedDoctorItem], otherDoctorsItems] = partition(
      (item) => item.personInfo?.id === selectedDoctorId,
      availableSlotsWithStuff,
    )

    const slotsForOtherDoctors = processSlots(otherDoctorsItems)

    const sortedSlotsForOtherDoctors = sort(
      (slot1, slot2) =>
        // @ts-ignore
        moment(slot1.from).format('x') - moment(slot2.from).format('x'),
      slotsForOtherDoctors,
    )
    const slotsForDoctor =
      selectedDoctorId && selectedDoctorItem
        ? processSlots([selectedDoctorItem])
        : sortedSlotsForOtherDoctors

    const selectedDoctor = Utils.findById(selectedDoctorId, doctors)

    const hasSlots = slotsForDoctor.length > 0
    const isToday = moment().isSame(selectedDate as string, 'day')
    const hasWorkingHours =
      Boolean(workingHours?.from) && Boolean(workingHours?.to)
    const isNotYetOpen =
      hasWorkingHours && moment().isBefore(workingHours?.from)
    const isAlreadyClosed =
      hasWorkingHours && moment().isAfter(workingHours?.to)
    const outsideOfWorkingHours =
      (isNotYetOpen || isAlreadyClosed) && slotsForDoctor.length === 0

    useEffect(() => {
      onCanProceedChange(Boolean(selectedSlot))
    }, [selectedSlot])

    useImperativeHandle(ref, () => ({
      onProceed: () => {
        const data = selectedDoctorId
          ? { veterinarianId: selectedStartSlot?.doctorId }
          : {}
        if (isSelectStartSlotStep) {
          if (isMultiday) {
            setSelectedEndDate(
              moment(selectedStartDate).add(1, 'd').toISOString(),
            )
            setStep(Steps.SELECT_END_SLOT)
          } else {
            onFinalize({
              ...data,
              personRoles: [],
              personResponsibilities: [],
              scheduledStartDatetime: selectedStartSlot?.from,
              scheduledEndDatetime: selectedStartSlot?.to,
            })
          }
        }

        if (isSelectEndSlotStep) {
          onFinalize({
            ...data,
            personResponsibilities: [],
            personRoles: [],
            scheduledStartDatetime: selectedStartSlot?.from,
            scheduledEndDatetime: selectedEndSlot?.to,
          })
        }
      },
      onBack: () => {
        if (isSelectStartSlotStep) {
          onBack()
        }

        if (isSelectEndSlotStep) {
          setSelectedEndDate(getInitialEndDate())
          setSelectedEndSlot(undefined)
          setStep(Steps.SELECT_START_SLOT)
        }
      },
    }))

    const getSlotDisabled = (slot: SelectedSlot) =>
      isSelectEndSlotStep && moment(slot.to).isBefore(selectedStartSlot?.from)

    return (
      <Grid container item className={classes.root} direction="column">
        <Text strong className={classes.title} variant="h2">
          {titleMap[step]}
        </Text>
        <Grid
          container
          item
          className={classes.content}
          wrap={isMobile ? 'wrap' : 'nowrap'}
        >
          <Grid item className={classes.calendarContainer} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={KioskMomentUtils}>
              <KioskCalendar
                disablePast
                showTodayButton={false}
                showTopPickers={false}
                value={selectedDate}
                variant="static"
                // @ts-ignore
                onChange={(date: string) => setSelectedDate(date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid container item direction="column" wrap="nowrap">
            <FormControl fullWidth margin="normal">
              <InputLabel shrink htmlFor="doctor-select">
                {t('Common:APPOINTMENT_WITH')}
              </InputLabel>
              <PuiSelect
                className={classes.doctorSelect}
                input={<Input id="doctor-select" />}
                items={doctors}
                placeholder={t('Common:NO_PREFERENCE')}
                value={selectedDoctorId}
                onChange={Utils.handleFormSelectInput(setSelectedDoctorId)}
              />
            </FormControl>
            <Grid
              container
              item
              alignContent={isLoading ? 'center' : 'flex-start'}
              className={classes.slotsContainer}
              justifyContent={isLoading ? 'center' : 'flex-start'}
              sm={6}
              spacing={2}
              xs={12}
            >
              {isLoading ? (
                <CircularProgress />
              ) : hasSlots ? (
                <CalendarSectionSlotList
                  getSlotDisabled={getSlotDisabled}
                  selectedSlot={selectedSlot}
                  slots={slotsForDoctor}
                  onSlotClick={setSelectedSlot}
                />
              ) : (
                <>
                  <Text variant="body2">
                    {hasWorkingHours
                      ? isToday && outsideOfWorkingHours
                        ? t(
                            'Common:SORRY_WE_ARE_CLOSED_FOR_TODAY_TRY_SELECT_FUTURE_DATE',
                          )
                        : selectedDoctorId
                        ? t(
                            'Common:NO_APPOINTMENTS_WITH_DOCTOR_ARE_AVAILABLE_TODAY',
                            {
                              doctorName: selectedDoctor.name,
                            },
                          )
                        : t('Common:NO_OPEN_TIME_SLOTS_ON_THIS_DATE')
                      : t('Common:WE_ARE_SORRY_WE_ARE_NOT_OPEN_ON_THIS_DAY')}
                  </Text>
                  {selectedDoctorId &&
                    sortedSlotsForOtherDoctors.length > 0 && (
                      <>
                        <Text strong className={classes.text} variant="h2">
                          {t('Common:AVAILABLE_TIMES_WITH_OTHER_PROVIDERS')}
                        </Text>
                        <CalendarSectionSlotList
                          getSlotDisabled={getSlotDisabled}
                          selectedSlot={selectedSlot}
                          slots={sortedSlotsForOtherDoctors}
                          onSlotClick={setSelectedSlot}
                        />
                      </>
                    )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  },
)

export default CalendarSection
