import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  DateUtils,
  Defaults,
  moment,
  NumberUtils,
  StripePaymentForm,
  Utils,
} from '@pbt/pbt-ui-components'

import Features from '../../../constants/features'
import { MembershipStatus } from '../../../constants/wellnessPlansConstants'
import {
  clearBraintreeSubscriptionError,
  getBrainTreeStateError,
  getBraintreeSubscriptionStatus,
  updateBraintreeMembershipSubscriptionStatus,
} from '../../../store/duck/braintree'
import { getCurrentBusiness } from '../../../store/duck/businesses'
import { getCurrentClient } from '../../../store/duck/clients'
import {
  getFeatureToggle,
  getWellnessPlanType,
} from '../../../store/duck/constants'
import { addLastError } from '../../../store/duck/errors'
import {
  fetchPaymentsPublicKey,
  getPaymentsPublicKey,
  getPaymentsSecretKey,
} from '../../../store/duck/payments'
import {
  checkMembershipSubscriptionStatus,
  getMembershipBillingAddress,
  getMembershipIsPortalEmbed,
  getMembershipSelection,
  getMembershipSubscriptionStatus,
  getMembershipToken,
  getWellnessPlansVersion,
} from '../../../store/duck/wellnessPlans'
import { useNavigateWithQueryString } from '../../../utils'
import KioskScreen from '../KioskScreen'
import { BraintreePaymentForm } from './BraintreePaymentForm/BraintreePaymentForm'
import { useTotalsPerPatient } from './wellnessPlanUtils'

const useStyles = makeStyles(
  (theme) => ({
    title: {
      textAlign: 'left',
    },
    content: {
      padding: theme.spacing(2, 5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    amountText: {
      color: R.prop('buttonsColor') || theme.colors.alertLabelError,
      fontWeight: 500,
      fontSize: '1.8rem',
    },
    labelContainer: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'MembershipPaymentMethodScreen' },
)

const MembershipPaymentMethodScreen = () => {
  const dispatch = useDispatch()
  const navigateWithQueryString = useNavigateWithQueryString()
  const { t } = useTranslation(['Common', 'Membership'])

  const publicKey = useSelector(getPaymentsPublicKey)
  const secretKey = useSelector(getPaymentsSecretKey)
  const client = useSelector(getCurrentClient)
  const billingAddress = useSelector(getMembershipBillingAddress)
  const isPortalEmbed = useSelector(getMembershipIsPortalEmbed)
  const token = useSelector(getMembershipToken)
  const membershipSubscriptionStatus = useSelector(
    getMembershipSubscriptionStatus,
  )

  const braintreeMembershipSubscriptionStatus = useSelector(
    getBraintreeSubscriptionStatus,
  )

  const braintreeMembershipSubscriptionError = useSelector(
    getBrainTreeStateError,
  )

  const selection = useSelector(getMembershipSelection)
  const currentBusiness = useSelector(getCurrentBusiness)
  const wellnessPlansVersion = useSelector(getWellnessPlansVersion)
  const WellnessPlanType = useSelector(getWellnessPlanType)
  const isWelcomeEmailForOmnichannelEnabled = useSelector(
    getFeatureToggle(Features.WELCOME_EMAIL_FOR_OMNICHANNEL),
  )

  const isBusinessOmniChannel = currentBusiness?.omniChannel

  const isIpoM2bBraintreeSignupEnabled = useSelector(
    getFeatureToggle(Features.IPO_M2B_BRAINTREE_SIGNUP),
  )

  const shouldUseBraintree =
    isIpoM2bBraintreeSignupEnabled && isBusinessOmniChannel

  const BaseTypeId = Utils.findConstantIdByName('Base', WellnessPlanType)
  const AddOnTypeId = Utils.findConstantIdByName('AddOn', WellnessPlanType)
  const PackageTypeId = Utils.findConstantIdByName('Package', WellnessPlanType)

  const allowedAutorenewalPlanTypeIds = [BaseTypeId, AddOnTypeId, PackageTypeId]

  const autorenewalPlanIds = R.pipe(
    R.filter(
      (plan) =>
        plan.autoRenewal &&
        !plan.hidden &&
        allowedAutorenewalPlanTypeIds.includes(plan.planTypeId),
    ),
    R.pluck('id'),
  )(wellnessPlansVersion?.plans)

  const hasSelectedAutorenewalPlans = selection.some((item) =>
    autorenewalPlanIds.includes(item.planId),
  )

  const { customColorsEnabled, buttonsColor } = currentBusiness || {}

  const classes = useStyles({
    buttonsColor: customColorsEnabled ? buttonsColor : undefined,
  })

  const stripePaymentFormRef = useRef()
  const braintreePaymentFormRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  // const [formComplete, setFormComplete] = useState(false)
  const [formComplete, setFormComplete] = useState(true)
  const [pollingInterval, setPollingInterval] = useState()

  useEffect(() => {
    if (!publicKey) {
      dispatch(fetchPaymentsPublicKey())
    }
    return () => {
      clearInterval(pollingInterval)
    }
  }, [])

  useEffect(() => {
    if (braintreeMembershipSubscriptionStatus) {
      dispatch(updateBraintreeMembershipSubscriptionStatus(false))
      if (isPortalEmbed) {
        window?.parent?.postMessage(
          {
            type: 'MEMBERSHIP_SIGN_UP_SUCCESS',
            selection: isWelcomeEmailForOmnichannelEnabled
              ? selection
              : undefined,
          },
          '*',
        )
      } else {
        navigateWithQueryString({ url: '/membership/success' })
      }
    }
    if (membershipSubscriptionStatus?.state === MembershipStatus.COMPLETED) {
      setIsLoading(false)
      clearInterval(pollingInterval)

      const subscriptionWithError = (
        membershipSubscriptionStatus.subscriptions || []
      ).find(R.propEq('state', 'ERROR'))

      if (subscriptionWithError) {
        navigateWithQueryString({ url: '/membership/sign-up-error' })
      } else if (isPortalEmbed) {
        window?.parent?.postMessage(
          {
            type: 'MEMBERSHIP_SIGN_UP_SUCCESS',
            selection: isWelcomeEmailForOmnichannelEnabled
              ? selection
              : undefined,
          },
          '*',
        )
      } else {
        navigateWithQueryString({ url: '/membership/success' })
      }
    }

    if (braintreeMembershipSubscriptionError) {
      navigateWithQueryString({ url: '/membership/sign-up-failure' })
      dispatch(clearBraintreeSubscriptionError())
    }
  }, [
    membershipSubscriptionStatus?.state,
    braintreeMembershipSubscriptionStatus,
    braintreeMembershipSubscriptionError,
  ])

  const onCardSetupSucceeded = () => {
    setIsLoading(true)
    setPollingInterval(
      setInterval(
        () => dispatch(checkMembershipSubscriptionStatus(token)),
        Defaults.DEBOUNCE_ACTION_TIME,
      ),
    )
  }

  const onCardSetupError = (error) => {
    setIsLoading(false)
    dispatch(
      addLastError({
        data: error.message,
      }),
    )
  }

  const onProceedStripe = async () => {
    try {
      setIsLoading(true)
      const { setupIntent, error } =
        (await stripePaymentFormRef.current.createPayment({
          client,
          billingAddress,
          clientSecret: secretKey,
        })) || {}

      if (error) {
        onCardSetupError(error)
      } else if (setupIntent?.status === 'succeeded') {
        onCardSetupSucceeded()
      }
    } catch (e) {
      onCardSetupError(e)
    }
  }

  const onProceedBraintree = async () => {
    try {
      setIsLoading(true)
      await braintreePaymentFormRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true }),
      )
    } catch (e) {
      onCardSetupError(e)
    }
  }

  const onProceed = () =>
    shouldUseBraintree ? onProceedBraintree() : onProceedStripe()

  const totalsPerPatient = useTotalsPerPatient(
    selection,
    client?.memberships || [],
    true,
  )
  const amount = Object.keys(totalsPerPatient).reduce((acc, patientId) => {
    const totalForPatient = Object.values(totalsPerPatient[patientId]).reduce(
      (result, value) => result + value,
      0,
    )
    return acc + totalForPatient
  }, 0)

  const renewDate = moment().add(1, 'year')

  return (
    <KioskScreen
      additionalLabel={
        amount > 0 && (
          <Grid
            container
            item
            alignContent="flex-end"
            className={classes.labelContainer}
            direction="column"
          >
            <Typography className={classes.amountText}>
              {t('Common:CARD_WILL_BE_CHARGED_AMOUNT', {
                amount: NumberUtils.formatMoney(amount),
              })}
            </Typography>
            {hasSelectedAutorenewalPlans && (
              <Typography variant="body2">
                {t('Membership:PLAN_AUTO_RENEWS_ON_DATE', {
                  date: DateUtils.formatDate(renewDate),
                })}
              </Typography>
            )}
          </Grid>
        )
      }
      alignItems="flex-start"
      classes={{
        title: classes.title,
        content: classes.content,
      }}
      justifyContent="flex-start"
      proceedButtonDisabled={isLoading || !formComplete}
      proceedButtonLabel={t('Common:SIGN_UP')}
      proceedButtonLoading={isLoading}
      title={t('Common:ADD_PAYMENT_METHOD')}
      onProceed={onProceed}
    >
      {shouldUseBraintree ? (
        <BraintreePaymentForm
          ref={braintreePaymentFormRef}
          setIsLoading={setIsLoading}
          onFormComplete={(complete) => setFormComplete(complete)}
        />
      ) : (
        <StripePaymentForm
          publicKey={publicKey}
          ref={stripePaymentFormRef}
          onCompleteStateChange={(complete) => setFormComplete(complete)}
        />
      )}
    </KioskScreen>
  )
}

export default MembershipPaymentMethodScreen
