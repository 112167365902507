enum Features {
  DEPOSIT_PAY_BY_LINK = 'DEPOSIT_PAY_BY_LINK',
  DISPLAY_CONSENT_FORMS_ON_KIOSK = 'DISPLAY_CONSENT_FORMS_ON_KIOSK',
  IPO_M2B_BRAINTREE_SIGNUP = 'IPO-M2B_BRAINTREE_SIGNUP',
  INPUT_FORMS_DOCUMENT = 'INPUT_FORMS_DOCUMENT',
  SMS_CONSENT_TOGGLE = 'SMS_CONSENT_TOGGLE',
  SUPPRESS_ADD_CLIENTS_AND_PATIENTS = 'SUPPRESS_ADD_CLIENTS_AND_PATIENTS',
  WELCOME_EMAIL_FOR_OMNICHANNEL = 'WELCOME_EMAIL_FOR_OMNICHANNEL',
}

export default Features
